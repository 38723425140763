import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import Moment from "moment";
import { jsPDF } from "jspdf";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CustomerLoader from "../CRM/Modal/CustomerLoder";

const UnitMainPage = ({hadlersuper}) => {
    const { _id, custId } = useParams("");
    const navigate = useNavigate();
    let token = localStorage.getItem("SuperAgentChromepayToken");
    const [pageCount, setpageCount] = useState("");
    const [show, setShow] = useState(false);
    const [addUnitshow, setShowAddUnit] = useState(false);
    const [data, setData] = useState([]);
    const [loan, setLoan] = useState([]);
    const [cust, setCustomer] = useState([]);
    const [emi, setEmi] = useState([]);
    const [emidata, setEmiData] = useState("");
    const [Piademidata, setPiademidata] = useState([]);
    const [Allinformationloan, setAllinformationloan] = useState("");
    const [loanInfoData, setLonaInfo] = useState("");
    const [PasswordVerifly, setPasswordVerifly] = useState(false);
    const [closeLoanStates, setcloseLoanStates] = useState(false);
    const [filterdata, setfilterdata] = useState("All");
    const [ShowLoader, setShowLoader] = useState(true);
  
    const [RepaymentEmi, setRepaymentEmi] = useState(0);
  
    const totalPrincipalAmount = useMemo(() => {
      let approvedDate = loanInfoData?.data?.approvedDate;
      const daysDifference = Math.floor(
        (new Date(approvedDate) - new Date()) / (24 * 60 * 60 * 1000)
      );
      let interestRate = loan?.interestRate / 100;
  
      var interestAmountCalculate =
        (RepaymentEmi * interestRate * Math.abs(daysDifference)) / 365;
  
      var perInt = (interestAmountCalculate * Math.abs(daysDifference))?.toFixed(
        2
      );
  
      let principal = (RepaymentEmi - perInt)?.toFixed(2);
      return { perInt, principal };
    }, [RepaymentEmi, loanInfoData, loan]);
  
    console.log({ totalPrincipalAmount });
  
    const limit = 10;
//   ------list
    const formsave = (e, page) => {
      e.preventDefault();
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = Formvlaues;
      let body = { loanId: _id, formData };
      console.log("Formvlaues === ", body);
      axios
        .post(`v1/super-Agent/get-productCategory`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          const data = resp.data.data;
          setEmi(data);
          return userlist();
        });
    };
  
    // ----list
    const loanDetail = async () => {
      let body = { loanId: _id, status: "PENDING" };
      await axios
        .post(`v1/super-Agent/get-productCategory`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          if (resp?.data?.status) {
            const data = resp.data.data;
            console.log("remove console",{data})
      
            setEmi(data);
            const total = resp.data.totalRow;
            console.log(total);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
            setShowLoader(false);
          }
          return userlist();
        });
    };
  
    // --------list
    const loanDetailPaid = async () => {
      let body = { loanId: _id, status: "PAID" };
      await axios
        .post(`v1/super-Agent/get-productCategory`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          const data = resp.data.data;
          const custmer = data?.customer;
          const emi = data?.EMIS;
          console.log("PaidEmi", emi);
          setPiademidata(emi);
          const total = resp.data.totalRow;
          console.log(total);
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          return userlist();
        });
    };


    const handledeleteunit = (_id) => {
        
    
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to Delete selected Customer ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .post(
                `/v1/super-Agent/remove-productCategory`,
                { categoryId : _id },
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => {
                let Data = response?.data;
                Swal.fire("Deleted!", "Customer Deleted   successfully", "success");
    
                setTimeout(() => {
                    loanDetail();
                }, 2000);
              })
              .catch();
          }
        });
      };
  
    const userlist = async () => {
      let body = { loanId: _id };
      await axios
        .post(`/v1/Agnet/get-pass-EMis`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          const data = resp.data.data;
          setData(data);
          console.log("pass" + data);
          const total = resp.data.totalRow;
          // console.log(total);
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
        });
    };
  
    const loanDetailcustomer = async () => {
      let body = { loanId: _id };
      await axios
        .post(`/v1/super-Agent/loan-detail`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          const data = resp.data.data;
          const loanData = resp.data;
          setLonaInfo(loanData);
          setAllinformationloan(data);
        });
    };
  
    useEffect(() => {
      userlist();
      loanDetail();
      hadlersuper();
      loanDetailPaid();
      loanDetailcustomer();
      setLonaInfo();
      PaylogsList();
      penaltieslogsList();
    }, []);
  
    useEffect(() => {
      PaylogsList();
    }, [filterdata]);
  
    // useEffect(() => {
    //   if (emi?.length > 0) {
    //     setLoanClosed(false);
    //   } else {
    //     setLoanClosed(true);
    //   }
    // }, [emi]);
  
    // Event handler for the onChange event of the select element
    // const handleSelectChange = (event) => {
    //   // Access the selected value from the select element
    //   const selectedValue = event.target.value;
    //   console.log("!!!"+selectedValue)
    //   // Update the state with the selected value
    //   setSelectedOption(selectedValue);
    // };
    ///////////////pagenestion///////////////
    const fetchComments = async (page) => {
      const senData = { page: page };

      axios
        .post(`v1/Agnet/get-loan-emis`, senData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          const data = resp.data.data;
          setData(data);
        });
      return data;
    };
  
    const handlePageClick = async (data) => {
      const page = data.selected + 1;
      const commentsFormServer = await fetchComments(page);
      setData(commentsFormServer);
    };
  
    const handleView = (_id) => {
      navigate(`/agent/emi-customer-detail/${_id}`);
    };
  
    const [penalties, setpenalties] = useState("");
  
    const handlePay = (_id, loanId) => {
      const body = { emiId: _id, loanId: loanId };
  
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to update units?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setPasswordVerifly(true);
          axios
            .post(
              `/v1/DID/check-penality`,
              { emiId: _id },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((resp) => {
              const data = resp.data.data;
              // Assuming you have a function like setData to handle data
              // setData(data);
              console.log("Penalty check successful:", data);
              setpenalties(data);
              // Correcting possible typo
            })
            .catch((error) => {
              console.error("Error checking penalty:", error);
              // Handle error as needed
            });
        }
      });
    };
  
    const VerfiyDelete = async (e) => {
      e.preventDefault();
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = Formvlaues;
      try {
        const response = await axios.post(
          `/v1/super-Agent/match-agent-pass`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        let data = response.data;
  
        if (closeLoanStates) {
          CloseloanUser();
          setPasswordVerifly(false);
        } else {
          if (data.status) {
            setPasswordVerifly(false);
            setShow(true);
          }
        }
      } catch (error) { }
    };
  
    const PayEmiFormData = async (e) => {
    //   setShowLoader(true);
      e.preventDefault();
      console.log({emidata});
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = {
        ...Formvlaues,
        categoryId: emidata._id, 
      };
     
      console.log("first",{Formvlaues});
      console.log("second",{formData});
  
      axios
        .post(`/v1/super-Agent/update-productCategory`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          const data = resp.data;
          console.log(data);
          if (data.status) {
            toast.success(data.msg);
            setShow(false);
            loanDetail();
            loanDetailPaid();
            loanDetailcustomer();
            PaylogsList();
            setRepaymentEmi(0)
            setShowLoader(false);
          } else {
            toast.error(data.msg);
            setShowLoader(false);
          }
        });
    };


    const addUnit = async (e) => {
        //   setShowLoader(true);
          e.preventDefault();
          const data = new FormData(e.target);
          const Formvlaues = Object.fromEntries(data.entries());
          const formData = Formvlaues;
          console.log(Formvlaues);
      
          axios
            .post(`v1/super-Agent/add-productCategory`, formData, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((resp) => {
              const data = resp.data;
              console.log(data);
              if (data.status) {
                toast.success(data.msg);
                setShowAddUnit(false);
                loanDetail();
                loanDetailPaid();
                loanDetailcustomer();
                PaylogsList();
                setRepaymentEmi(0)
                setShowLoader(false);
              } else {
                toast.error(data.msg);
                setShowLoader(false);
              }
            });
        };
      
  
    const handleDownloadReceipt = (ReceiptData) => {
      console.log({ ReceiptData });
  
      if (ReceiptData.downloadChecked) {
        const pdf = new jsPDF();
  
        // Add content to the PDF
        pdf.text("Receipt", 20, 10);
        pdf.text(`Loan ID: ${ReceiptData.loanId}`, 20, 20);
        pdf.text(`EMI ID: ${ReceiptData.emiId}`, 20, 30);
        pdf.text(`Amount: ${ReceiptData.amount}`, 20, 40);
        pdf.text(`Payment Type: ${ReceiptData.payType}`, 20, 50);
        pdf.text("Thank you for your payment!", 20, 60);
  
        // Save the PDF
        pdf.save("receipt.pdf");
      } else {
        alert("Please check the checkbox to download the receipt.");
      }
    };
  
    const [PaylogaData, setPaylogData] = useState([]);
    const [pagelogs, setpagelogs] = useState("");
  
    const PaylogsList = async (e) => {
      axios
        .post(
          `/v1/DID/loan-pay-logs`,
          { loanId: _id, payType: filterdata },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((resp) => {
          const data = resp.data;
          console.log("paylogs", { data });
          if (data.status) {
            const total = resp.data.totalRow;
            console.log({ total });
            const totalPage = Math.ceil(total / limit);
            setpagelogs(totalPage);
            setPaylogData(data.data);
          } else {
            toast.error(data.msg);
          }
        });
    };
  
    const handlePageClickLogs = async (Size) => {
      try {
        const page = Size.selected + 1;
        const senData = { loanId: _id, payType: filterdata, page: page };
  
        const resp = await axios.post(`/v1/DID//loan-pay-logs`, senData, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        const data = resp.data;
        console.log("paylogs", { data });
        if (data.status) {
          const total = resp.data.totalRow;
          console.log({ total });
          const totalPage = Math.ceil(total / limit);
          setpagelogs(totalPage);
          setPaylogData(data.data);
        } else {
          toast.error(data.msg);
        }
      } catch (error) {
        console.error(error);
        // Handle error appropriately, e.g., set an error state
        throw error; // Re-throw the error to propagate it if needed
      }
    };
  
    const [penaltiesData, setpenaltiesData] = useState([]);
  
    const [penaltieslogs, setpenaltieslogs] = useState("");
  
    const penaltieslogsList = async (e) => {
      axios
        .post(
          `/v1/DID/get-penality`,
          { loanId: _id },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((resp) => {
          const data = resp.data;
          console.log("penalties", { data });
          if (data.status) {
            const total = resp.data.totalRow;
            console.log({ total });
            const totalPage = Math.ceil(total / limit);
            setpenaltieslogs(totalPage);
            setpenaltiesData(data.data);
          } else {
            toast.error(data.msg);
          }
        });
    };
  
    const handlePageClickpenalties = async (Size) => {
      try {
        const page = Size.selected + 1;
        const senData = { page: page, loanId: _id };
  
        const resp = await axios.post(`/v1/DID/loan-pay-logs`, senData, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        const data = resp.data;
        console.log("paylogs", { data });
        if (data.status) {
          const total = resp.data.totalRow;
          console.log({ total });
          const totalPage = Math.ceil(total / limit);
          setpenaltieslogs(totalPage);
          setPaylogData(data.data);
        } else {
          toast.error(data.msg);
        }
      } catch (error) {
        console.error(error);
        // Handle error appropriately, e.g., set an error state
        throw error; // Re-throw the error to propagate it if needed
      }
    };
  
    const CloseloanUser = async (e) => {
    //   setShowLoader(true);
  
      axios
        .post(
          `/v1/super-Agent/close-cust-loan`,
          {
            loanId: _id,
            custId: custId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((resp) => {
          const data = resp.data;
          console.log(data);
          if (data.status) {
            toast.success(data.msg);
            setcloseLoanStates(false);
            loanDetailcustomer();
      setShowLoader(false);
  
            // handleDownloadReceipt(Formvlaues);
          } else {
            toast.error(data.msg);
      setShowLoader(false);
  
          }
        });
    };
  
    return (
      <>
        <ToastContainer />
        {/* <CustomerLoader loader={ShowLoader} /> */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl" id="kt_content_container">
              <div className="row g-5 ">
              
                
                <div className="col-lg-12">
                  <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                    <div class="card-rounded mt-2  d-flex flex-stack flex-wrap pt-0 p-5 pb-5">
                      <h5 className="text-light" style={{marginTop : "20px", fontSize : "20px"}}>Unit Management</h5>
                    </div>
                  </div>
                </div>
                <div class="card-title text-end">
                      <button
                        onClick={() => setShowAddUnit(true)}
                        className="btn btn-light btn-active-light-primary me-2"
                      >
                        Add Category
                      </button>
                    </div>
                <div className="col-lg-12">
                  <div class="tab-content mt-4" style={{ padding: "0px" }}>
                    <div
                      class="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                    Category List
                                  </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                            
                            </div>
                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="pending-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted text-center th-title">
                                      <th className="min-w-125px">#</th>
                                      <th className="min-w-150px">
                                      Title
                                      </th>
                                      {/* <th className="min-w-150px">
                                      Status
                                      </th> */}
                                      {/* <th className="min-w-100px">Operator</th> */}
                                      {/* <th className="min-w-100px">Operation Value</th> */}
                                      <th className="min-w-100px">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
  
                                  <tbody>
                                    {emi?.length > 0 ? (
                                      emi.map((item, index) => (
                                        <tr className="text-center" key={index}>
                                          <td>{[index + 1]}</td>
                                          <td>{item?.title}</td>
                                          {/* <td>{item?.status||"Active"}</td> */}
                                          <td>
                                          <div className=" justify-content-start flex-wrap flex-shrink-0 gap-3 d-flex">
                                          <button
                                                onClick={() => [
                                                  handlePay(
                                                    item?._id,
                                                    item?.loanId
                                                  ),
                                                  setEmiData(item),
                                                ]}
                                                className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                data-bs-trigger="hover"
                                                title="Update Unit"
                                                style={{
                                                  width:
                                                    "calc(3.5em + 1.4rem + 2px)",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span className="svg-icon svg-icon-3">
                                                  <i className="fad fa-credit-card fs-4"></i>
                                                </span>
                                              </button>

                                              <button
                                  
                                    onClick={(e) => {
                                        handledeleteunit(item._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete Unit"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>
                                  </div>
  </td>

  
                                         
                                        
                                          
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="11" className="text-center">
                                          <span
                                            style={{
                                              color: "green",
                                              fontSize: "2rem",
                                            }}
                                          >
                                            No Category Found Please Add First
                                                                                      </span>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-end gap-2"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="tab-pane fade" id="documents" role="tabpanel">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  PAID EMI LIST
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn btn-success btn-sm"
                                  table="emi-table-to-xls"
                                  filename="Emi_Paid_List"
                                  sheet="EmiPaidList"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>
                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="emi-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted text-center th-title">
                                      <th className="min-w-125px">EMI-ID</th>
                                      <th className="min-w-150px">
                                        Total Amount
                                      </th>
                                      <th className="min-w-150px">
                                        Interest Rate
                                      </th>
                                      <th className="min-w-100px">EMI Number</th>
                                      <th className="min-w-100px">EMI Amount</th>
                                      <th className="min-w-100px">EmiPay Date</th>
                                      <th className="min-w-125px">
                                        Remaining Amount
                                      </th>
                                      <th className="min-w-125px">Loan Amount</th>
                                      <th className="min-w-125px">
                                        Interest Amount
                                      </th>
                                      <th className="min-w-100px">PayStatus</th>
                                      <th className="min-w-100px text-center">
                                        Statement
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Piademidata?.map((item, index) => (
                                      <tr className="text-center" key={index}>
                                        <td>{item?._id?.slice(-7)}</td>
                                        <td>{item?.totalAmount?.toFixed(2)}</td>
                                        <td>
                                          <span>{`${item?.interestRate}%`}</span>
                                        </td>
                                        <td>{item?.emiNumber}</td>
                                        <td>{item?.emiAmount?.toFixed(2)}</td>
                                        <td>
                                          {moment(item?.emiPayDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {item?.remainingAmount?.toFixed(2)}
                                        </td>
                                        <td>{item?.loanAmount}</td>
                                        <td>{item?.intrestAmount?.toFixed(2)}</td>
                                        <td>
                                          <span
                                            className={`badge fs-5 ${item?.payStatus === "PENDING"
                                                ? "badge-light-pending"
                                                : "badge-light-info"
                                              }`}
                                          >
                                            {item?.payStatus}
                                          </span>
                                        </td>
                                        <td>
                                          <div className="card-title m-2">
                                            <button
                                              className="btn btn-primary"
                                              onClick={(e) => {
                                                navigate(
                                                  `/LoanPaidInvoive/${item?._id}/${custId}`
                                                );
                                              }}
                                            >
                                              <i className="bi bi-printer me-1"></i>{" "}
                                              Print Statement
                                            </button>
                                          </div>{" "}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-end gap-2"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="tab-pane fade" id="PayLogs" role="tabpanel">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  PAY LOGS LIST
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <div className="row align-items-center">
                                <div className="col-lg-8">
                                  <div className="d-flex">
                                    <select
                                      name="filterType"
                                      className="form-select border-0 flex-grow-1"
                                      data-placeholder="Please Select"
                                      onChange={(e) =>
                                        setfilterdata(e.target.value)
                                      }
                                    >
                                      <option value="All">All</option>
                                      <option value="voluntary">Voluntary</option>
                                      <option value="mandatory">Mandatory</option>
                                      <option value="Bankdeposit">
                                        Bank deposit
                                      </option>
                                      <option value="Cash">Cash</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-4 text-end">
                                  <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-success btn-sm"
                                    table="logs-table-to-xls"
                                    filename="PayLogs"
                                    sheet="PayLogs"
                                    buttonText={
                                      <i className="fad fa-download fs-1"></i>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="logs-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted text-center th-title">
                                      <th className="min-w-125px">
                                        Repayment Date
                                      </th>
                                      <th className="min-w-150px">
                                        Repayment Amount
                                      </th>
                                      <th className="min-w-150px">Interest</th>
                                      <th className="min-w-100px">
                                        Principal Repayment
                                      </th>
                                      {/* <th className="min-w-100px">Remaining Principal</th> */}
                                      <th className="min-w-100px">Pay Type</th>
                                      <th className="min-w-100px">Status</th>
                                      <th className="min-w-100px">Statement</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {PaylogaData?.map((item, index) => (
                                      <tr className="text-center" key={index}>
                                        <td>
                                          {moment(item?.lastPayDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {parseFloat(item?.amount)?.toFixed(2) ||
                                            "0.00"}
                                        </td>
                                        <td>
    {parseFloat(item?.interestPayAmount) < 0
      ? "0.00"
      : parseFloat(item?.interestPayAmount)?.toFixed(2) || "0.00"}
  </td>
  
                                        <td>
                                          {parseFloat(
                                            item?.principalRepayment
                                          )?.toFixed(2) || "0.00"}
                                        </td>
                                        {/* <td>{Math.round(item?.remainingAmount)}</td> */}
                                        <td className="text-capitalize">
                                          {item?.payType || "Mandatory"}
                                        </td>
                                        <td>
                                          <span className="badge badge-light-info fs-5 capitalize-text">
                                            Success
                                          </span>
                                        </td>
                                        <td>
                                          <div className="card-title m-2">
                                            <button
                                              className="btn btn-primary"
                                              onClick={(e) => {
                                                navigate(
                                                  `/LoanInvoive/${item?._id}/${custId}`
                                                );
                                              }}
                                            >
                                              <i className="bi bi-printer me-1"></i>{" "}
                                              Print Statement
                                            </button>
                                          </div>{" "}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pagelogs}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClickLogs}
                                  containerClassName={
                                    "pagination justify-content-end gap-2"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="tab-pane fade" id="penalties" role="tabpanel">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1 text-uppercase">
                                  PENALTY LIST
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn btn-sm btn-success"
                                  table="penalties-table-to-xls"
                                  filename="penalties"
                                  sheet="penalties"
                                  buttonText={
                                    <i className="fad fa-download fs-1"></i>
                                  }
                                />
                              </div>
                            </div>
                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="penalties-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-center text-muted th-title">
                                      <th className="min-w-125px">Loan Id</th>
                                      <th className="min-w-150px">
                                        Penalties Amount
                                      </th>
                                      <th className="min-w-100px">
                                        Principal Repayment
                                      </th>
                                      <th className="min-w-100px">
                                        Remaining Principal
                                      </th>
                                      <th className="min-w-100px">Reason</th>
                                      <th className="min-w-100px">Status</th>
                                      <th className="min-w-100px">
                                        Last Pay Date
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {penaltiesData?.map((item, index) => (
                                      <tr className="text-center" key={index}>
                                        <td>{item?.loanId?.slice(10)}</td>
                                        <td>{item?.amount?.toFixed(2)}</td>
                                        <td>
                                          {item?.principalRepayment?.toFixed(2)}
                                        </td>
                                        <td>
                                          {item?.remainingAmount?.toFixed(2)}
                                        </td>
                                        <td>{item?.reason}</td>
                                        <td>
                                          <span className="badge badge-light-info fs-5 capitalize-text">
                                            Deducted
                                          </span>
                                        </td>
                                        <td>
                                          {moment(item?.lastPayDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={penaltieslogs}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClickpenalties}
                                  containerClassName={
                                    "pagination justify-content-end gap-2"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    {/* <div class="tab-pane fade" id="land" role="tabpanel">
           <div class="row">
           </div>
           </div> */}
                    {/* <div class="tab-pane fade" id="finance" role="tabpanel">
           <div class="row">
           </div>
           </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title> Update Units </Modal.Title>
          </Modal.Header>
          <form onSubmit={(e) => PayEmiFormData(e)}>
            <Modal.Body>
              <div className="col-lg-12 ">

  
               
                <div className="mb-3">
                  <label className="mb-2 bt-2">Title</label>
                  <input
                    className="form-control"
                    placeholder="Enter Title"
                    type="text"
                    name="title"
                    // pattern="^\d*(\.\d{0,2})?$" // Allows up to two decimal places
                     defaultValue={emidata?.title}

                  />
                </div>
                            </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
              // onClick={() => formhandle()}
              >
                {" "}
                Add Category
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
  
        {/* verify password */}
  
        <Modal
          className="modal-center"
          show={PasswordVerifly}
          onHide={() => setPasswordVerifly(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Verify Password</Modal.Title>
          </Modal.Header>
          <form onSubmit={VerfiyDelete}>
            <Modal.Body>
              <div className="col-lg-12 ">
                <div className="row">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    {" "}
                    Password
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="password"
                      name="password"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Enter password"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit" className="center">
                {" "}
                Verify
              </Button>
            </Modal.Footer>
          </form>
        </Modal>



        {/* let add unit */}


        <Modal show={addUnitshow} onHide={() => setShowAddUnit(false)}>
          <Modal.Header closeButton>
            <Modal.Title> Add Category </Modal.Title>
          </Modal.Header>
          <form onSubmit={(e) => addUnit(e)}>
            <Modal.Body>
              <div className="col-lg-12 ">
                <div className="mb-3">
                  <label className="mb-2 bt-2">Title</label>
                  <input
                    className="form-control"
                    placeholder="Enter Title"
                    type="text"
                    name="title"
                    defaultValue={emidata?.title}

                  />
                 </div>
                
             
               
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowAddUnit(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
              >
                {" "}
                Add Category
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }

export default UnitMainPage
