import React from 'react'
import { useEffect, useState } from 'react';
import { useNavigate ,useLocation} from 'react-router-dom';
import AutoAddress from '../../Mapcomponents/AutoAddress';


function SuperAddressInformation({ hadlersuper }) {
    const navigate = useNavigate();
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [currentStep, setCurrentStep] = useState(2);
    const [selectedProfession, setSelectedProfession] = useState('');
    const [otherProfession, setOtherProfession] = useState('');
    const location = useLocation().state;


    console.log("--->>>location", location)

    const shippingStages = ['Profile', 'Address', 'Document'];
    const handleClick = () => {
        const latInnerLat = document.getElementById("lat").innerHTML;
        console.log(latInnerLat);

        setLat(latInnerLat);

        const latInnerLong = document.getElementById("lon").innerHTML;
        console.log(latInnerLong);

        setLong(latInnerLong);
    };

    const handleProfessionChange = (value) => {
        setSelectedProfession(value);
    };

    const profession = [
        { value: 'Not Employeed', label: 'Not Employeed' },
        { value: 'Student', label: 'Student' },
        { value: 'Farmer', label: 'Farmer' },
        { value: 'Merchant', label: 'Merchant' },
        { value: 'Teacher', label: 'Teacher' },
        { value: 'Accountant', label: 'Accountant' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Software developer', label: 'Software developer' },
        { value: 'Banker', label: 'Banker' },
        { value: 'Driver', label: 'Driver' },
        { value: 'Assistant', label: 'Assistant' },

    ];

    const AddInfo = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        console.log(Formvlaues);
        // let dataToSend2 = new FormData();
        //     dataToSend2.append("nationality", Formvlaues.nationality);
        // dataToSend2.append("profession", Formvlaues.profession);
        // dataToSend2.append("address", Formvlaues.address);
        // localStorage.setItem("image", Formvlaues.image);
        localStorage.setItem("nationality", Formvlaues.nationality);
        localStorage.setItem("professoin", Formvlaues.professoin);
        localStorage.setItem("address", Formvlaues.address);
        localStorage.setItem("Latitude", Formvlaues.Latitude);
        localStorage.setItem("Longitude", Formvlaues.Longitude);
        // localStorage.setItem("email", Formvlaues.email);
        // localStorage.setItem("gender", Formvlaues.gender);

        navigate("/agent/customer/document",{
            state : {...Formvlaues, ...location}
        });

    }



    useEffect(() => {
        hadlersuper();
    }, [])




    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCdkMLmt8vv54OmKcp4c174eK4t7J1Xgzk&libraries=places&v=weekly&callback=initMap`;
        script.defer = true;
        document.head.appendChild(script);

        return () => {

            document.head.removeChild(script);
        };
    }, []);


    return (
        <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
        >
            <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
            >



                <div class="container-xxl" id="kt_content_container">

                    <div class="row g-5 g-xl-8">
                        <div class="col-lg-12">

                            <div class="card mb-5 mb-xl-10">

                                <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                    <div class="card-title m-0">
                                        <h3 class="fw-bold m-0">Address Customer</h3>
                                    </div>
                                    <div class="my">
                                        <div class="margin-area">
                                            <div class="dot one">1</div>
                                            <div class="dot two">2</div>
                                            <div class="dot three">3</div>
                                            {/* <div class="dot four">4</div> */}
                                            <div class="progress-bar first"></div>
                                            <div class="progress-bar second" ></div>
                                            {/* <div class="progress-bar third"></div> */}
                                            <div class="message message-1">
                                                Profile
                                            </div>`
                                            <div class="message message-2">Address</div>
                                            <div class="message message-3">Document</div>
                                            {/* <div class="message message-4">Delivered</div> */}

                                        </div>

                                    </div>
                                </div>


                                <div id="kt_account_settings_profile_details" class="collapse show">

                                    <form onSubmit={AddInfo} >

                                        <div class="card-body border-top p-9">


                                            {/* //////////map input filed hidden */}
                                            <input
                                                type="hidden"
                                                name="Latitude"
                                                value={lat}
                                            />
                                            <input
                                                type="hidden"
                                                name="Longitude"
                                                value={long}
                                            />
                                            {/* ////////// end map input filed hidden */}



                                            <div class="row mb-6">
                                                <label class="col-lg-4 col-form-label fw-semibold fs-6">Nationality</label>
                                                <div class="col-lg-8 fv-row">
                                                    <input type="text" defaultValue="Ethiopian" name="nationality" class="form-control form-control-lg form-control-solid" placeholder="Nationality" />
                                                </div>
                                            </div>

                                            <div class="row mb-6">
                                                <label class="col-lg-4 col-form-label fw-semibold fs-6">Profession</label>
                                                <div class="col-lg-8 fv-row">
                                                    <select
                                                        type="text"
                                                        name="professoin"
                                                        class="form-control form-control-lg form-control-solid"
                                                        placeholder="professoin"
                                                        onChange={(e) => handleProfessionChange(e.target.value)}
                                                    >
                                                        <option value="">Select an option</option>
                                                        {profession.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    {selectedProfession === 'Other' && (
                                                        <input
                                                            type="text"
                                                            name="otherProfession"
                                                            class="form-control form-control-lg form-control-solid mt-2"
                                                            placeholder="Enter your profession"
                                                            value={otherProfession}
                                                            onChange={(e) => setOtherProfession(e.target.value)}
                                                        />
                                                    )}
                                                </div>
                                            </div>


                                            {/* <AutoAddress style={{ width: "100% !important" }} /> */}






                                        </div>
                                        <div class="card-footer d-flex justify-content-end py-5 px-9">
                                            {/* <button type="reset" class="btn btn-light btn-active-light-primary me-2">Reset</button> */}
                                            <button type="submit" onClick={handleClick} class="btn btn-primary" id="kt_account_profile_details_submit">Next</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">

                    <div class="container-xxl d-flex flex-column flex-md-row flex-stack">

                        <div class="text-dark order-2 order-md-1">
                            <span class="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                            <a href="#" class="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
                        </div>


                        <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                            <li class="menu-item">
                                <a href="#" class="menu-link px-2">About</a>
                            </li>
                            <li class="menu-item">
                                <a href="#" class="menu-link px-2">Support</a>
                            </li>

                        </ul>

                    </div>

                </div>


            </div>
        </div>
    )
}


export default SuperAddressInformation