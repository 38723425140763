import Header from "../Header";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ReactPaginate from "react-paginate";
import { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "./Modal/CustomerLoder";
import AddSavingSuperAgentModal from "../SavingsManagment/SavingModal/AddSavingSuperAgentModal/AddSavingSuperAgentModal";

var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Savingcustomer({ hadlersuper }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userPro, setUserinfo] = useState([]);
  const [userLoanDetail, setUserLoan] = useState([]);
  const [allSavingsTotal, setAllSavingsTotal] = useState([]);
  const [NumberOfsavings, setNuberOfSavings] = useState("");
  const [TypeOfsavings, setTypeOfsavings] = useState("");
  const [savingsHistory, setsavingsHistory] = useState([]);
  const [savingsExistingHistory, setExistingSavingsHistory] = useState([]);
  const [exwithdrawalHistory, setexwithdrawalHistory] = useState([]);
  const [fullinfosharewithdraw, setfullinfosharewithdraw] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [message, setMessage] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [dateOfExisting, setDateOfExisting] = useState(0);
  const [ShowLoader, setShowLoader] = useState(false);
  const [shareDivided, setShareDivided] = useState(false);
  const [SavingPrice, setSavingPrice] = useState(0);
  const [savingTypes, setSavingTypes] = useState([]);

  const [VoluntaryToMandatoryApiData, setVoluntaryToMandatoryApiData] =
    useState([]);
  const [interestData, setInterstSaving] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [page, setpage] = useState("");
  const [SIpage, setSIpage] = useState("");
  const [transId, setTransId] = useState("");
  const [SharePrice, setCurrSharePrice] = useState("");
  const [isOldManage, setIsOld] = useState(false);
  const [formData, handleInputChange] = useState({
    amount: "",
  });
  const [custID, setcustID] = useState("");
  const [WithtypeOfsavings, setWithTypeOfsavings] = useState("");
  const [withAmount, setWithAmount] = useState("");
  const [Delethandler, setDelethandler] = useState(false);
  const [existingSavHandle, setExistingSavhandler] = useState(false);
  const [Candelhandler, setCancelhandler] = useState(false);
  const [withdrawdata, setwithdrawdata] = useState("all");
  const [intrestfilterawdata, setintrestfilterawdata] = useState("mandatory");
  const [activeTab, setactiveTab] = useState("add-saving");
  const [Interestpercentage, setInterestpercentage] = useState();
  const [withTypeSavingString, setWithTypeOfsavingsString] = useState("");
  const [isShare, setIsShare] = useState(false);
  const [ChildrenInformation, setChildrenInformation] = useState([]);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [childrenNameSelect, setChildrenNameSelect] = useState('');
  const [savingString, setSavingString] = useState('');

  console.log("selectedChildren", selectedChildren);



  let token = localStorage.getItem("SuperAgentChromepayToken");
  let { _id } = useParams();







  const handleIntrestvalue = (e) => {
    let type = e.target.value;

    console.log("test-type-isShare", { type })

    setTypeOfsavings(type);

    const selectedSaving = savingTypes.find(
      (option) => option._id === type
    );
    setChildrenNameSelect(selectedSaving)
    setSavingString(selectedSaving?.type)

    console.log({ selectedSaving })
    // Set the isShare value based on the selected option
    if (selectedSaving) {
      setIsShare(selectedSaving.isShare);
      setInterestpercentage(selectedSaving?.interestRate || 10)
    }
  };



  const handlegetChildreninfo = (e) => {
    axios
      .post(
        `/v1/DID/get-cust-childrens`,
        { custId: _id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          setChildrenInformation(resp?.data?.data || [])
        } else {
          toast.error("error");
        }
      });
  };





  const handleIntrestvalueForWithdrawal = (e) => {
    let type = e.target.value;
    console.log("test-type-withdrawal", type)

    const selectedSaving = savingTypes.find(
      (option) => option._id === type
    );

    if (selectedSaving) {
      console.log("test-with-savings", selectedSaving)
      setWithTypeOfsavings(selectedSaving);
      setIsShare(selectedSaving.isShare);
      setInterestpercentage(selectedSaving?.interestRate || 10)
      setWithTypeOfsavingsString(selectedSaving.type)
    }
  };


  console.log("test-isShare", { isShare })

  function formatLargeNumber(Amount, decimalPlaces) {
    // Check if amount is a number
    let amount = parseFloat(Amount);

    if (isNaN(amount)) {
      return "Invalid input";
    }

    // Round to the specified number of decimal places
    amount = amount.toFixed(decimalPlaces);

    // Separate integer and decimal parts
    const [integerPart, decimalPart] = amount.split(".");

    // Add commas to the integer part
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    // Concatenate integer and decimal parts
    let formattedAmount = formattedIntegerPart;
    if (decimalPart !== undefined && decimalPart !== "") {
      formattedAmount += "." + decimalPart;
    }

    return formattedAmount;
  }

  const paymentSendInProgressUser = (item) => {
    let _id = item._id;
    console.log({ _id });
    if (item.status === "cancel") {
      toast.error("This transaction has already been canceled.");
    }

    setTransId(_id);
    setCancelhandler(true);
  };

  const SavinglatestPrice = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-share-per`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          console.log("ppppp", data);
          if (data.status) {
            console.log("ppppp", data.data);
            setSavingPrice(data.data);

            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };

  const getTypesOfSaving = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-types`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          console.log("test-saving-types", data);
          if (data.status) {
            console.log("ppppp", data.data);
            setSavingTypes(data.data);

            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };



  const currenrSharePrice = () => {
    axios
      .post(
        `/v1/super-Agent/get-super-share-price`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        console.log("share=price", data);

        setCurrSharePrice(data);
      });
  };

  const handleCancelTransaction = async () => {
    // if (!selectedTransaction) {
    //   setMessage('Please Select a transaction');
    //   return;
    // }

    // const { _id } = selectedTransaction;
    console.log("testcancel");

    try {
      const response = await axios.post(
        `/v1/super-Agent/cancel-saving-trans`,
        { transId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("testcancel", response);

      if (response.data.status) {
        VoluntaryToMandatoryhistoryApi();
        SavinguserDeatailshistory();
        ShareCalculation();
        custumerLoanDetail();
        toast.success(response.data.msg);
      } else {
        setMessage("Failed to cancel transaction");
        toast.error(response.data.msg);
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  const AddFormData = async (e) => {



    let child = null; // Default to null

    if (selectedChildren) {
      child = ChildrenInformation?.find((child) =>
        child?.name?.toLowerCase() === (selectedChildren?.toString().toLowerCase() || "")
      );
    }


    const formData = {
      custId: _id,
      typeId: TypeOfsavings,
      interest: Interestpercentage,
      amount: withAmount,
      isShare,
      childrenName: selectedChildren,
      childrenAge: child?.age || null, // Fallback if age is undefined
    };


    axios
      .post(`/v1/DID/add-union-saving`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        setWithAmount("");
        setTypeOfsavings("");

        if (resp.status) {
          e.target.reset();
          let data = resp.data;
          if (data.status) {
            setShowLoader(false);
            SavinguserDeatailshistory();
            custumerLoanDetail();
            ShareCalculation();
            setTypeOfsavings("");
            toast.success(data.msg);
            // window.location = "/agent-list";
            setInterestpercentage("");
            e.target.reset();
          } else {
            toast.error(data.msg);
            setShowLoader(false);
          }
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AddExistingSaving = async (e) => {
    // e.preventDefault();
    // var data = new FormData(e.target);

    // var Formvlaues = Object.fromEntries(data.entries());
    // Formvlaues.custId = _id;

    setShowLoader(true);

    const formData = {
      custId: _id,
      accType: TypeOfsavings,
      interest: interestRate,
      amount: withAmount,
      date: dateOfExisting,
      isShareAllow: shareDivided,
      typeId: TypeOfsavings, 
    };

    axios
      .post(`/v1/DID/create-existing-saving`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            setShowLoader(false);
            SavinguserDeatailshistory();
            ExistingSavinguserDeatailshistory();
            custumerLoanDetail();
            ShareCalculation();
            e.target.reset();
            toast.success(data.msg);
            setDateOfExisting("");
            setExistingSavhandler(false);
            setInterestRate("");
            setTypeOfsavings("");
            setWithAmount("");
            // window.location = "/agent-list";
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
          setShowLoader(false);
        }
      });
  };

  const WithdrawalFormData = (e) => {
    setShowLoader(true);
    // e.target.reset();
    // Determine the API endpoint and payload dynamically
    const apiEndpoint = WithtypeOfsavings?.type === "mandatory"
      ? "/v1/super-Agent/withdrawal-request"
      : "/v1/super-Agent/withdraw-saving";

    const payload = WithtypeOfsavings?.type === "mandatory"
      ? { custId: _id, amount: userLoanDetail.mandatory }
      : { custId: _id, amount: withAmount, type: WithtypeOfsavings?._id };

    axios
      .post(apiEndpoint, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        toast.success(data.msg);

        // Common actions after successful response
        setWithAmount("");
        setWithTypeOfsavings("");
        custumerLoanDetail();
        WithdrawalDeatailshistoryApi();
        setShowLoader(false);

        // Specific actions based on the withdrawal type
        if (WithtypeOfsavings?.type === "voluntary") {
          VoluntaryToMandatoryhistoryApi();
          SavinguserDeatailshistory();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || "An error occurred");
        console.error("Error:", error);
        setShowLoader(false);
      });
  };


  const custumerDetails = () => {
    axios
      .post(
        `/v1/super-Agent/cust-detail`,
        { custID: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.Data;
        console.log("---->>>>--->>>", data);
        setUserinfo(data);

        console.log("data===== filter", data);
      });
  };

  const ShareCalculation = () => {
    axios
      .post(
        `v1/super-Agent/share-calculation`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data;
        console.log("Share", { data });
        setfullinfosharewithdraw(data?.data);
      });
  };

  const custumerLoanDetail = () => {
    axios
      .post(
        `/v1/DID/get-both-saving-amount`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        // let data = resp.data.data;
        let saveData = resp.data;
        // let numberOfSavings = resp.data.savingData.length;
        console.log("---->>>>--->>>Loan", saveData);
        // console.log("")
        setUserLoan(saveData);
        setAllSavingsTotal(saveData.findAllTotalSavings);
        // NuberOfSavings(numberOfSavings);

        console.log("data===== filter", data);
      });
  };

  // withdraw tab data code start

  const [WithdrawalData, setWithdrawalData] = useState([]);
  const [Withdpage, setWithdpage] = useState();

  const WithdrawalDeatailshistoryApi = () => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId: _id,
          page: 1,
          type: "withdrawal",
          savingType: withdrawdata,
          isOld: isOldManage,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setWithdrawalData(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        console.log("=-=-=->", { totalPage });
        setWithdpage(totalPage || 0);

        console.log("Usersaving", total);
      });
  };

  useEffect(() => {
    WithdrawalDeatailshistoryApi();
    getTypesOfSaving();
  }, []);
  useEffect(() => {
    WithdrawalDeatailshistoryApi();
  }, [withdrawdata]);

  const handleWithdrawalPageChange = async (data) => {
    try {
      const page = data.selected + 1;
      const resp = await axios.post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId: _id,
          page,
          type: "withdrawal",
          savingType: "",
          isOld: isOldManage,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const saveData = resp.data.data;
      setWithdrawalData(saveData);

      const total = resp.data.totalRaw;
      const totalPage = Math.ceil(total / limit);
      console.log("Total Pages:", totalPage);
      setWithdpage(totalPage || 0);

      console.log("Total Withdrawal Data:", total);
    } catch (error) {
      console.error("Error occurred while fetching withdrawal data:", error);
    }
  };

  // withdraw tab data code end

  // existing  withdrwal code

  const [ExWithdrawalData, setExWithdrawalData] = useState([]);
  const [ExWithdpage, setExWithdpage] = useState();

  const ExWithdrawalDeatailshistoryApi = (oldstatus) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId: _id,
          page: 1,
          type: "withdrawal",
          savingType: "",
          isOld: oldstatus,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setExWithdrawalData(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        console.log("=-=-=->", { totalPage });
        setExWithdpage(totalPage || 0);

        console.log("Usersaving", total);
      });
  };

  useEffect(() => {
    ExWithdrawalDeatailshistoryApi();
    handlegetChildreninfo();
  }, []);

  const handleEXWithdrawalPageChange = async (data) => {
    try {
      const page = data.selected + 1;
      const resp = await axios.post(
        `/v1/super-Agent/get-existing-saving-logs`,
        {
          custId: _id,
          page: 1,
          type: "withdrawal",
          savingType: "",
          isOld: isOldManage,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const saveData = resp.data.data;
      setExWithdrawalData(saveData);

      const total = resp.data.totalRaw;
      const totalPage = Math.ceil(total / limit);
      console.log("Total Pages:", totalPage);
      setExWithdpage(totalPage || 0);

      console.log("Total Withdrawal Data:", total);
    } catch (error) {
      console.error("Error occurred while fetching withdrawal data:", error);
    }
  };

  // exwithdrwa end

  // saving Pagination done start

  // saving Pagination done code
  const limit = 5;

  const SavinguserDeatailshistory = (oldstatus) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId: _id,
          page: 1,
          type: "deposite",
          savingType: "",
          isOld: oldstatus,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setsavingsHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        console.log("=-=-=->", { totalPage });
        setpageCount(totalPage || 0);

        console.log("Usersaving", total);
      });
  };
  const ExistingSavinguserDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/get-existing-saving-logs`,
        { custId: _id, page: 1, type: "deposite", savingType: "", isOld: true },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp?.data?.data || [];
        setExistingSavingsHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        console.log(
          "=-=tetetettetetetettetetsttestetstetstetstetstetstst-=->",
          { totalPage }
        );
        console.log("=-=-=->", { totalPage });
        setpageCount(totalPage || 0);

        console.log("Usersaving", total);
      });
  };

  const ExistingSavinguserDeatailshistorywith = () => {
    axios
      .post(
        `/v1/super-Agent/get-existing-saving-logs`,
        {
          custId: _id,
          page: 1,
          type: "withdrawal",
          savingType: withdrawdata,
          isOld: true,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setexwithdrawalHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage || 0);

        console.log("Usersaving", total);
      });
  };

  const fetchCommentspage = async (page) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId: _id,
          page: page,
          type: "deposite",
          savingType: filterdata,
          isOld: isOldManage,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setsavingsHistory(saveData);
        console.log("Usersaving", saveData);

        console.log("Usersaving", data);
      });
    return data;
  };

  const fetchExistingCommentspage = async (page, type) => {
    axios
      .post(
        `/v1/super-Agent/get-existing-saving-logs`,
        {
          custId: _id,
          page: page,
          type: type,
          savingType: filterdata,
          isOld: true,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setExistingSavingsHistory(saveData);
        console.log("Usersaving", saveData);

        console.log("Usersaving", data);
      });
    return data;
  };

  const fetchExistingCommentspagewith = async (page, type) => {
    axios
      .post(
        `/v1/super-Agent/get-existing-saving-logs`,
        {
          custId: _id,
          page: page,
          type: type,
          savingType: filterdata,
          isOld: true,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setexwithdrawalHistory(saveData);
        console.log("Usersaving", saveData);

        console.log("Usersaving", data);
      });
    return data;
  };

  const handlePageClickpage = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentspage(page);
  };

  const handleExistPageClickpage = async (data) => {
    const page = data.selected + 1;
    const type = "deposite";
    const commentsFormServer = await fetchExistingCommentspage(page, type);
  };

  const handleExistPageClickpagewithd = async (data) => {
    const page = data.selected + 1;
    const type = "withdrawal";
    const commentsFormServer = await fetchExistingCommentspagewith(page, type);
  };

  const [filterdata, setfilterdata] = useState("all");

  const fitterAddSavingApi = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId: _id,
          page: 1,
          savingType: filterdata,
          type: "deposite",
          isOld: isOldManage,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setsavingsHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage || 0);
      })
      .catch(error);
  };

  const fitterExistingAddSavingApi = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-existing-saving-logs`,
        {
          custId: _id,
          page: 1,
          savingType: filterdata,
          type: "deposite",
          isOld: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setExistingSavingsHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage || 0);
      })
      .catch(error);
  };

  useEffect(() => {
    console.log({ filterdata });
    fitterAddSavingApi();
    ShareCalculation();
    currenrSharePrice();
    fitterExistingAddSavingApi();
  }, [filterdata]);

  // saving Pagination done end

  // withdraw Pagination done code  start

  const VoluntaryToMandatoryhistoryApi = () => {
    axios
      .post(
        `/v1/super-Agent/trasfer-logs`,
        { custId: _id, page: 1 },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setVoluntaryToMandatoryApiData(saveData);
        console.log("withrawtesttras", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpage(totalPage || 0);
        console.log("withrawtesttras", data);
      });
  };

  // const VoluntaryToMandatoryhistoryApi = (e) => {
  //   axios
  //     .post(
  //       `/v1/super-Agent/get-saving-logs`,
  //       { custId: _id, page: 1, savingType: withdrawdata, type: "withdrawal" },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     )
  //     .then((resp) => {
  //       let saveData = resp.data.data;
  //       // setVoluntaryToMandatoryApiData(saveData);
  //       console.log("vm", saveData);
  //       const total = resp.data.totalRaw;
  //       const totalPage = Math.ceil(total / limit);
  //       setpage(totalPage || 0);
  //       console.log("vm", data);
  //     })
  //     .catch(error);
  // };

  // useEffect(() => {
  //   VoluntaryToMandatoryhistoryApi();
  // }, [withdrawdata]);

  // withdraw Pagination done code  end

  // Saving intrest api call

  const interestdatasApiCall = () => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest-transactions`,
        {
          custId: _id,
          page: 1,
          type: intrestfilterawdata == "all" ? "madatory" : intrestfilterawdata,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setInterstSaving(saveData);
        console.log("withraw", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setSIpage(totalPage);
        console.log("withraw", data);
      });
  };

  const fetchCommentsavingintrest = async (page) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest-transactions`,
        {
          custId: _id,
          page: 1,
          type: intrestfilterawdata == "all" ? "madatory" : intrestfilterawdata,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setInterstSaving(saveData);
        console.log("interest---am", data);
        console.log("saving", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setSIpage(totalPage);
      });
  };

  const SavingPageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentsavingintrest(page);
  };

  useEffect(() => {
    interestdatasApiCall();
  }, [intrestfilterawdata]);

  // const WithdrawalFormData = async (e) => {
  //   e.preventDefault();
  //   var data = new FormData(e.target);

  //   var Formvlaues = Object.fromEntries(data.entries());
  //   Formvlaues.custId = _id;

  //   const formData = Formvlaues;

  //   console.log({ Formvlaues });

  //   axios
  //     .post(`/v1/super-Agent/withdraw-saving`, formData, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })

  //     .then((resp) => {
  //       if (resp.status) {
  //         let data = resp.data;
  //         if (data.status) {
  //           toast.success(data.msg);
  //           // window.location = "/agent-list";
  //           e.target.reset();
  //           custumerLoanDetail();
  //           VoluntaryToMandatoryhistoryApi();
  //         } else {
  //           toast.error(data.msg);
  //         }
  //       } else {
  //         toast.error(data.msg);
  //       }
  //     });
  // };

  const VoluntaryToMandatory = async (e) => {
    // e.preventDefault();
    // var data = new FormData(e.target);

    // var Formvlaues = Object.fromEntries(data.entries());
    // Formvlaues.custId = _id;

    // const formData = Formvlaues;
    // const amount = Formvlaues.amount;
    setShowLoader(true);
    let payload = {
      amount: withAmount,
      custId: _id,
      savingam: withAmount - (withAmount * SavingPrice) / 100,
      shaream: (withAmount * SavingPrice) / 100,
      sharePrice: SharePrice,
    };

    if (parseInt(withAmount) > parseInt(userLoanDetail?.volantory)) {
      Swal.fire({
        icon: "error",
        title: "Insufficient Funds",
        text: "You cannot withdraw this amount; your wallet does not have sufficient voluntary funds.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      axios
        .post(`/v1/super-Agent/Transfer-Fund`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })

        .then((resp) => {
          if (resp.status) {
            let data = resp.data;
            if (data.status) {
              toast.success(data.msg);
              setShowLoader(false);
              custumerLoanDetail();
              VoluntaryToMandatoryhistoryApi();
              ShareCalculation();
              setExistingSavhandler(false);
              setWithAmount("");
            } else {
              toast.error(data.msg);
              setShowLoader(false);
            }
          } else {
            toast.error(data.msg);
            setShowLoader(false);
          }
        });
    }
  };

  const interestRatecount = async (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        if (resp.status) {
          let data = resp?.data?.data;
          setNuberOfSavings(data);
        } else {
          toast.error(data.msg);
        }
      });
  };

  // api call
  useEffect(() => {
    hadlersuper();
    SavinguserDeatailshistory();
    custumerDetails();
    custumerLoanDetail();
    VoluntaryToMandatoryhistoryApi();
    interestRatecount();
    interestdatasApiCall();
    ExistingSavinguserDeatailshistory();
    ExistingSavinguserDeatailshistorywith();
    SavinglatestPrice();
  }, []);

  const DeleteVeriflyAfterSubmit = (e) => {
    e.preventDefault();
    if (!withAmount) {
      toast.error("Enter your Amount");
    } else if (!TypeOfsavings) {
      toast.error("Selecte Types of Saving");
    } else if (withAmount && TypeOfsavings) {
      setDelethandler(true);
    }
  };

  const VerfiyDelete = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    console.log({ WithtypeOfsavings, savingTypes, activeTab })



    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;

      if (data.status) {
        setShowLoader(false);
        setDelethandler(false);

        if (activeTab === "add-saving") {
          AddFormData(e);
        } else if (["voluntary", "mandatory"].includes(WithtypeOfsavings?.type)) {
          const withdrawalMessages = {
            voluntary: {
              title: "Are you sure?",
              text: "Are You Sure To Withdrawal Amount.",
            },
            mandatory: {
              title: "Are you sure?",
              text: "Are you certain you want to withdraw the entire balance? Please note that upon withdrawal, your mandatory account will be closed permanently.",
            },
          };

          const { title, text } = withdrawalMessages[WithtypeOfsavings?.type];

          Swal.fire({
            title,
            text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm it!",
          }).then((result) => {
            if (result.isConfirmed) {
              WithdrawalFormData(e);
            }
          });
        } else {
          WithdrawalFormData(e);
        }
      } else {
        setDelethandler(true);
        setDelethandler(false);
        toast.error(response.data.message);
      }

    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  const VerfiyExistingSav = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;

      if (data.status) {
        setShowLoader(false);
        setExistingSavhandler(false);
        if (activeTab == "Addexistingsavings") {
          AddExistingSaving(e);
        } else if (activeTab == "VoluntaryToMandatory") {
          VoluntaryToMandatory(e);
          e.target.reset();
        } else if (activeTab == "withdrawExisting") {
          WithdrawalExistingSaving(e);
          e.target.reset();
        }
        // e.target.reset();
      } else {
        toast.error(response.data.message);
        setExistingSavhandler(true);
        setShowLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  const VerfiyCancel = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;

      if (data.status) {
        e.target.reset();
        if (data.status) {
          setCancelhandler(false);
          Swal.fire({
            title: "Are you sure?",
            text: "Are You Sure To Cancel Transaction.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm it!",
          }).then((result) => {
            if (result.isConfirmed) {
              handleCancelTransaction();
              VoluntaryToMandatoryhistoryApi();
              custumerLoanDetail();
              SavinguserDeatailshistory();
              setCancelhandler(false);
              ExWithdrawalDeatailshistoryApi();
              WithdrawalDeatailshistoryApi();
              // e.target.reset();
            }
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "Are you certain you want to Cancel the entire amount?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm it!",
          }).then((result) => {
            if (result.isConfirmed) {
              handleCancelTransaction();
              VoluntaryToMandatoryhistoryApi();
              custumerLoanDetail();
              SavinguserDeatailshistory();
              setCancelhandler(false);
              // e.target.reset();
            }
          });
        }
      } else {
        toast.error(response.data.message);
        setCancelhandler(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  const Check_userAmount_to_wallet = (e) => {
    e.preventDefault();
    let usertypevalue = withAmount;

    if (activeTab == "Addexistingsavings") {
      if (!TypeOfsavings) {
        toast.error("Types of Saving is required");
        return false;
      }
    }

    if (withTypeSavingString == "mandatory") {
      if (usertypevalue > userLoanDetail.mandatory) {
        Swal.fire({
          icon: "error",
          title: "Insufficient Funds",
          text: "You cannot withdraw this amount; your wallet does not have sufficient mandatory funds.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        // DeleteVeriflyAfterSubmit();
        setDelethandler(true);
        // setTimeout(() => {
        //   e.target.reset()
        // }, 5000);
      }
    } else if (withTypeSavingString == "voluntary") {
      if (usertypevalue > userLoanDetail?.volantor) {
        Swal.fire({
          icon: "error",
          title: "Insufficient Funds",
          text: "You cannot withdraw this amount; your wallet does not have sufficient voluntary funds.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        // DeleteVeriflyAfterSubmit();
        // setTimeout(() => {
        //   e.target.reset()
        // }, 5000);
        setDelethandler(true);
      }
    } else if (["Children", "Interest-Free", "Voluntary Saving", "Voluntary test"].includes(WithtypeOfsavings?.type)) {
      setDelethandler(true);

    }

    else {
      setExistingSavhandler(true);
    }
  };

  // 55555555

  const [formDataWithdrawalExisting, setformDataWithdrawalExisting] = useState({
    amount: "",
    type: "",
    interestrate: "",
    dateOfWithd: "",
    custId: _id,
  });

  const handleChangeWithdrawalExisting = (e) => {
    const { name, value } = e.target;
    let data = { ...formDataWithdrawalExisting };
    if (name == "type") {
      if (value == "mandatory") {
        data.interestrate = NumberOfsavings?.mandatory;
      } else {
        data.interestrate = NumberOfsavings?.voluntary;
      }
    }
    data[name] = value;
    console.log('extering withdrawer',{data})
    setformDataWithdrawalExisting(data);
  };

  const WithdrawalExistingSaving = async (e) => {
    setShowLoader(true);
    // e.preventDefault();
    // var data = new FormData(e.target);

    // var Formvlaues = Object.fromEntries(data.entries());
    // Formvlaues.custId = _id;

    axios
      .post(
        `/v1/super-Agent/withdrawal-existing-saving`,
        formDataWithdrawalExisting,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        setWithAmount("");

        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            e.target.reset();
            setShowLoader(false);
            SavinguserDeatailshistory();
            custumerLoanDetail();
            ShareCalculation();
            e.target.reset();
            toast.success(data.msg);
            setTypeOfsavings("");
            setExistingSavhandler(false);
            ExWithdrawalDeatailshistoryApi();
            setExistingSavingsHistory();
            ExistingSavinguserDeatailshistorywith();

            setformDataWithdrawalExisting({
              amount: "",
              type: "",
              interestrate: "",
              dateOfWithd: "",
              custId: _id,
            });
          } else {
            toast.error(data.msg);
            setShowLoader(false);
          }
        } else {
          toast.error(data.msg);
          setShowLoader(false);
        }
      });
  };

  const handleSetActiveTabAndAmount = (e, title, oldstatus) => {
    setactiveTab(title);
    setWithAmount("");
    setTypeOfsavings("");
    setWithTypeOfsavings("");
    ExWithdrawalDeatailshistoryApi(oldstatus);
    WithdrawalDeatailshistoryApi();
    SavinguserDeatailshistory(oldstatus);
    ExistingSavinguserDeatailshistory();
  };

  const handleAddSavingDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Saving Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);

    // Add the share details table
    if (invoiceData.payType == "mandatory") {
      doc.autoTable({
        startY: 140,
        head: [
          [
            "Share ID",
            "Saving ID",
            "Share Price (ETB)",
            "Saving Interest %",
            "Share Quantity",
            "Saving Type",
          ],
        ],
        body: [
          [
            invoiceData.shareId,
            invoiceData.savingId,
            ` ${invoiceData.sharePrice}`,
            `${invoiceData.interest || 0}`,
            invoiceData.quantity,
            invoiceData.savingType,
          ],
        ],
      });

      // Add the total share amount and total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 10
      );
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 20
      );
    } else {
      doc.autoTable({
        startY: 140,
        head: [["Saving ID", "Saving Interest %", "Saving Type"]],
        body: [
          [invoiceData.savingId, `${invoiceData.interest || 0}`, "Voluntary"],
        ],
      });

      // Add the total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 20
      );
    }

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  const handlewithdrawalDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc?.internal?.pageSize?.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Withdrawal Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Withdrawal Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);

    // Add the share details table
    if (invoiceData.payType == "mandatory") {
      doc.autoTable({
        startY: 140,
        head: [
          [
            "Share ID",
            "Saving ID",
            "Share Price (ETB)",
            "Saving Interest %",
            "Share Quantity",
            "Saving Type",
          ],
        ],
        body: [
          [
            invoiceData.shareId,
            invoiceData.savingId,
            ` ${invoiceData.sharePrice}`,
            `${invoiceData.interest || 0}`,
            invoiceData.quantity,
            invoiceData.savingType,
          ],
        ],
      });

      // Add the total share amount and total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 10
      );
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 20
      );
    } else {
      doc.autoTable({
        startY: 140,
        head: [["Saving ID", "Saving Interest %", "Saving Type"]],
        body: [
          [invoiceData.savingId, `${invoiceData.interest || 0}`, "Voluntary"],
        ],
      });

      // Add the total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData?.totalAmount}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 20
      );
    }

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  const handleVoluntaryToMandatoryDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Saving Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);

    // Add the share details table

    doc.autoTable({
      startY: 140,
      head: [
        [
          "Share ID",
          "Saving ID",
          "Share Price (ETB)",
          "Saving Price (ETB)",
          "Share Quantity",
          "Payment Method",
        ],
      ],
      body: [
        [
          invoiceData.shareId,
          invoiceData.savingId,
          ` ${invoiceData.sharePrice}`,
          `${invoiceData.sharePrice || 0}`,
          invoiceData.quantity,
          "Mandatory",
        ],
      ],
    });

    // Add the total share amount and total saving amount on the right side in a smaller font size
    const totalTextSize = 10;
    const totalX = width - 60; // Adjust as needed
    doc.setFontSize(totalTextSize);
    doc.text(
      `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
      totalX,
      doc?.autoTable?.Previous?.finalY + 10
    );
    doc.text(
      `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
      totalX,
      doc?.autoTable?.Previous?.finalY + 20
    );

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  const handleAddExistingSavingDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Existing Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Existing Saving Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);

    // Add the share details table
    if (invoiceData.payType == "mandatory") {
      doc.autoTable({
        startY: 140,
        head: [
          [
            "Share ID",
            "Saving ID",
            "Share Price (ETB)",
            "Saving Interest %",
            "Share Quantity",
            "Saving Type",
          ],
        ],
        body: [
          [
            invoiceData.shareId,
            invoiceData.savingId,
            ` ${invoiceData.sharePrice}`,
            `${invoiceData.interest || 0}`,
            invoiceData.quantity,
            invoiceData.savingType,
          ],
        ],
      });

      // Add the total share amount and total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 10
      );
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 20
      );
    } else {
      doc.autoTable({
        startY: 140,
        head: [["Saving ID", "Saving Interest %", "Saving Type"]],
        body: [
          [invoiceData.savingId, `${invoiceData.interest || 0}`, "Voluntary"],
        ],
      });

      // Add the total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
        totalX,
        doc?.autoTable?.Previous?.finalY + 20
      );
    }

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  const handleDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Saving Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);

    // Add the share details table

    doc.autoTable({
      startY: 140,
      head: [
        [
          "Share ID",
          "Saving ID",
          "Share Price (ETB)",
          "Saving Price (ETB)",
          "Share Quantity",
          "Payment Method",
        ],
      ],
      body: [
        [
          invoiceData.shareId,
          invoiceData.savingId,
          ` ${invoiceData.sharePrice}`,
          `${invoiceData.sharePrice || 0}`,
          invoiceData.quantity,
          invoiceData.payType,
        ],
      ],
    });

    // Add the total share amount and total saving amount on the right side in a smaller font size
    const totalTextSize = 10;
    const totalX = width - 60; // Adjust as needed
    doc.setFontSize(totalTextSize);
    doc.text(
      `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
      totalX,
      doc?.autoTable?.Previous?.finalY + 10
    );
    doc.text(
      `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
      totalX,
      doc?.autoTable?.Previous?.finalY + 20
    );

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  const wathdrawalExistinghandleDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance

    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Withdrawal Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);

    // Add the share details table

    doc.autoTable({
      startY: 140,
      head: [["Super Agent ID", "Saving Price (ETB)", "Payment Method"]],
      body: [
        [
          invoiceData.superAgentId,

          `${invoiceData.sharePrice || 0}`,

          invoiceData.payType,
        ],
      ],
    });

    // Add the total share amount and total saving amount on the right side in a smaller font size
    const totalTextSize = 10;
    const totalX = width - 80; // Adjust as needed
    doc.setFontSize(totalTextSize);
    doc.text(
      `Total Withdrawal Amount (ETB):  ${invoiceData.totalAmount || 0}`,
      totalX,
      doc?.autoTable?.Previous?.finalY + 10
    );
    doc.text(
      `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
      totalX,
      doc?.autoTable?.Previous?.finalY + 20
    );

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              {/* <!--begin: Pic--> */}
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={
                                      userPro.IDphoto ||
                                      "/assets_new/images/lodingwallet.gif"
                                    }
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              {/* <!--end::Pic--> */}

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  {/* <!--begin::User--> */}
                                  <div className="d-flex flex-column">
                                    {/* <!--begin::Name--> */}
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {userPro.fullname}
                                      </a>

                                      {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                    </div>
                                    {/* <!--end::Name--> */}

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.professoin}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.nationality || "Ethiopia"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.email}
                                      </a>
                                    </div>
                                    {/* <!--end::Info--> */}
                                  </div>
                                  {/* <!--end::User--> */}
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap"></div>
                                    <div className="d-flex  flex-wrap mt-3">
                                      {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {userLoanDetail?.manStatus ===
                                            "Open" ? (
                                              formatLargeNumber(
                                                userLoanDetail?.mandatory,
                                                2
                                              )
                                            ) : (
                                              <span style={{ color: "red" }}>
                                                {userLoanDetail?.manStatus}
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Mandatory Savings (ETB)
                                        </div>
                                      </div> */}



                                      {allSavingsTotal.map((item) => (
                                        <div
                                          key={item.typeId}
                                          className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96 w-135"
                                        >
                                          <div className="d-flex align-items-center justify-content-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >
                                              {formatLargeNumber(item.totalAmount || 0, 2)}
                                            </div>
                                          </div>
                                          <div className="fw-semibold fs-6 text-capitalize text-white text-center">
                                            {item.typeName} Savings (ETB)
                                          </div>
                                        </div>
                                      ))}




                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              fullinfosharewithdraw?.quantity ||
                                              0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Share's Quantity
                                        </div>
                                      </div>
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              fullinfosharewithdraw?.totalAmount ||
                                              0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Share's Amount (ETB)
                                        </div>
                                      </div>

                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${
                                              userLoanDetail?.totalWithdrawal ||
                                              0
                                            }`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total withdraw
                                        </div>
                                      </div> */}
                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${
                                              userLoanDetail?.totalInterest Rate|| 0
                                            }`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Intrest Amount
                                        </div>
                                      </div> */}
                                    </div>
                                    <div className="d-flex mt-3">
                                      {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${NumberOfsavings || 0}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total NUmber Of Savings
                                        </div>
                                      </div> */}
                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${data?.Loans || " N/A"}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Loans
                                        </div>
                                      </div>
                                      <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${data?.Savings || " N/A"}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Savings
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>

                                  {/* <!--begin::Progress--> */}

                                  {/* <!--end::Progress--> */}
                                </div>
                              </div>
                              {/* <!--end::Info--> */}
                            </div>
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                // onClick={() => {
                                //   setactiveTab("add-saving"), setWithAmount("");
                                // }}

                                onClick={(e) => {
                                  handleSetActiveTabAndAmount(e, "add-saving");
                                  setIsOld(false);
                                }}
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                ADD SAVINGS
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                onClick={(e) => {
                                  handleSetActiveTabAndAmount(e, "withdrawal");
                                  setIsOld(false);
                                }}
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                WITHDRAWAL
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                // onClick={() => {
                                //   setactiveTab(""), setWithAmount("");
                                // }}
                                onClick={(e) => {
                                  handleSetActiveTabAndAmount(
                                    e,
                                    "VoluntaryToMandatory"
                                  );
                                  setIsOld(false);
                                }}
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#VoluntaryToMandatory"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Voluntary To Mandatory
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                // onClick={() => {
                                //   setactiveTab(""), setWithAmount("");
                                // }}
                                onClick={(e) =>
                                  handleSetActiveTabAndAmount(
                                    e,
                                    "Savinginterest"
                                  )
                                }
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#Savinginterest"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Saving Interest
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                onClick={(e) => {
                                  handleSetActiveTabAndAmount(
                                    e,
                                    "Addexistingsavings",
                                    true
                                  );
                                  // setIsOld(true);
                                }}
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#Addexistingsavings"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Add existing savings
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                onClick={(e) => {
                                  handleSetActiveTabAndAmount(
                                    e,
                                    "withdrawExisting",
                                    true
                                  );
                                  // setIsOld(true);
                                }}
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#withdrawExisting"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Withdrawal Existing SAVINGS
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    {/* Add saving  done  1111*/}

                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) =>
                                      DeleteVeriflyAfterSubmit(e)
                                    }
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount (ETB)
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                                required
                                                min={1}
                                                value={withAmount}
                                                onChange={(e) => {
                                                  let inputValue =
                                                    e.target.value;
                                                  {
                                                    setWithAmount(
                                                      parseFloat(inputValue)
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Types of Saving
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <select
                                                name="typeId"
                                                required
                                                className="form-select  flex-grow-1"
                                                value={TypeOfsavings}
                                                data-placeholder="Please Select"
                                                onChange={
                                                  handleIntrestvalue
                                                }
                                              >
                                                {/* add saving lists */}
                                                <option
                                                  value=""
                                                  selected
                                                  disabled
                                                >
                                                  Please Select Saving
                                                </option>
                                                {savingTypes.map((option) => (
                                                  <option
                                                    key={option.type}
                                                    value={option._id}
                                                  >
                                                    {option.type}
                                                  </option>
                                                ))}{" "}
                                              </select>
                                            </div>
                                          </div>
                                        </div>

                                        {childrenNameSelect?.type === 'Children' && <div className="col-lg-12 mb-8">
                                            {ChildrenInformation?.length > 0 ? (
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Select Children
                                                </label>
                                                <div className="col-lg-8 fv-row">
                                                  <select
                                                    name="typeId"
                                                    required
                                                    className="form-select flex-grow-1"
                                                    data-placeholder="Please Select"
                                                    onChange={(e) => setSelectedChildren(e.target.value)}
                                                  >
                                                    <option value="" selected disabled>
                                                      Please Select a Child
                                                    </option>
                                                    {ChildrenInformation.map((option) => (
                                                      <option key={option.name} value={option.name}>
                                                        {option.name}
                                                      </option>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            ) : (
                                              <p className="text-muted">No children information is available to select from. Please add children details to proceed.</p>
                                            )}

                                          </div>}

                                        <>
                                          {isShare && (
                                            <>
                                              <div className="col-lg-12 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                    Share Amount (ETB)
                                                  </label>
                                                  <div className="col-lg-8 fv-row">
                                                    <input
                                                      type="number"
                                                      name="amount"
                                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                      placeholder="Amount"
                                                      step="any"
                                                      required
                                                      value={
                                                        (withAmount *
                                                          SavingPrice) /
                                                        100
                                                      }
                                                      onChange={(e) => {
                                                        let inputValue =
                                                          e.target.value;

                                                        setWithAmount(
                                                          parseFloat(inputValue)
                                                        );
                                                      }}
                                                      min={0}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-12 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                    Total Saving Amount (ETB)
                                                  </label>
                                                  <div className="col-lg-8 fv-row">
                                                    <input
                                                      type="number"
                                                      name="amount"
                                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                      placeholder="Amount"
                                                      required
                                                      step="any"
                                                      value={
                                                        withAmount -
                                                        (withAmount *
                                                          SavingPrice) /
                                                        100
                                                      }
                                                      onChange={(e) => {
                                                        let inputValue =
                                                          e.target.value;

                                                        setWithAmount(
                                                          parseFloat(inputValue)
                                                        );
                                                      }}
                                                      min={0}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </>

                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Interest Rate
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="interest"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Interest Rate"
                                              step="any"
                                              value={Interestpercentage}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="cardlabel fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      <select
                                        name="marriageStatus"
                                        className="form-select  flex-grow-1"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                          setfilterdata(e.target.value)
                                        }
                                      >
                                        <option value={"all"}>All</option>
                                        <option value={"voluntary"}>
                                          Voluntary
                                        </option>
                                        <option value={"mandatory"}>
                                          Mandatory
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Share
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>
                                      <th className="min-w-100px">
                                        Interest
                                        <br /> Rate{" "}
                                      </th>
                                      {/* <th className="min-w-100px">Type</th> */}
                                      <th className="min-w-100px ">
                                        Status <br />
                                      </th>
                                      <th className="min-w-100px ">
                                        Cancel <br />
                                      </th>
                                      <th className="min-w-100px ">
                                        Invoice <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {savingsHistory.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          {moment(item.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item?.totalAmount}</td>
                                        <td>{item?.shareAmount || 0}</td>
                                        <td>
                                          {item.savingType}
                                        </td>
                                        <td>{item?.interest}</td>
                                        {/* <td>
                                          {item.type == "deposite"
                                            ? "Deposit"
                                            : item.type}
                                        </td> */}
                                        <td>
                                          {item.status == "cancel" ? (
                                            <span className="text-danger">
                                              Cancelled
                                            </span>
                                          ) : (
                                            <span className="text-success">
                                              Success
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            // onClick={() => handleCancelTransaction(item._id)}
                                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="Cancel"
                                            onClick={(e) => {
                                              paymentSendInProgressUser(item);
                                            }}
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <img
                                                src="/assets_new/images/cross.png"
                                                width="20px"
                                              />
                                            </span>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              handleAddSavingDownloadInvoice(
                                                item?.invoice
                                              )
                                            }
                                          >
                                            Download Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClickpage}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <AddSavingSuperAgentModal custId={_id} /> */}

                    {/* withdraw done */}

                    <div
                      className="tab-pane fade"
                      id="documents"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) =>
                                      Check_userAmount_to_wallet(e)
                                    }
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-1">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount (ETB)
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                value={
                                                  WithtypeOfsavings?.type ==
                                                    "mandatory"
                                                    ? userLoanDetail.mandatory
                                                    : withAmount
                                                }
                                                required
                                                step="any"
                                                min={1}
                                                onChange={(e) => {
                                                  let inputValue =
                                                    e.target.value;

                                                  setWithAmount(
                                                    parseFloat(inputValue)
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 mb-2">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Types of Withdraw
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <select
                                              name="type"
                                              required

                                              onChange={
                                                // setWithTypeOfsavings(
                                                //   e.target.value
                                                // )

                                                handleIntrestvalueForWithdrawal
                                              }
                                              className="form-select  flex-grow-1"
                                              data-placeholder="Please Select"
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Please Select Unit
                                              </option>
                                              {savingTypes.map((option) => (
                                                <option
                                                  key={option.type}
                                                  value={option._id}
                                                >
                                                  {option.type}
                                                </option>
                                              ))}{" "}
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          // onClick={() =>
                                          //   Check_userAmount_to_wallet()
                                          // }
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  {/* <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="all"
                                          onChange={(e) =>
                                            setwithdrawdata(e.target.value)
                                          }
                                          checked={withdrawdata == "all"}
                                          className="form-check-input"
                                        />
                                        All
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="mandatory"
                                          onChange={(e) =>
                                            setwithdrawdata(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Mandatory
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="voluntary"
                                          onChange={(e) =>
                                            setwithdrawdata(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Voluntary
                                      </label>
                                    </div>
                                  </div> */}

                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    {/* <div className="d-flex">
                                      <select
                                        name="marriageStatus"
                                        className="form-select  flex-grow-1"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                          setwithdrawdata(e.target.value)
                                        }
                                      >
                                        <option value={"all"}>All</option>
                                        <option value={"voluntary"}>
                                          Voluntary
                                        </option>
                                        <option value={"mandatory"}>
                                          Mandatory
                                        </option>
                                      </select>
                                    </div> */}
                                  </div>
                                </div>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="withdraw-table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  table="withdraw-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>
                                      {/* <th className="min-w-100px">
                                        Intrest
                                        <br /> Rate{" "}
                                      </th> */}
                                      <th className="min-w-100px text-center">
                                        Status <br />
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Cancel <br />
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Dowanload Invoice <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {WithdrawalData.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          {moment(item.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item.totalAmount.toFixed(2)}</td>
                                        <td>{item.type}</td>
                                        {/* <td>
                                          {item.remainingAmount.toFixed(2)}
                                        </td> */}
                                        <td>
                                          {item.status == "cancel" ? (
                                            <span className="text-danger">
                                              Cancelled
                                            </span>
                                          ) : (
                                            <span className="text-success">
                                              Success
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="Cancel"
                                            onClick={(e) => {
                                              paymentSendInProgressUser(item);
                                            }}
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <img
                                                src="/assets_new/images/cross.png"
                                                width="20px"
                                              />
                                            </span>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              handlewithdrawalDownloadInvoice(
                                                item.invoice
                                              )
                                            }
                                          >
                                            Download Invoice
                                          </button>
                                        </td>
                                        {/* <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                            <a
                                              href="digital_user_detail.html"
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={Withdpage}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handleWithdrawalPageChange}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* withdawal existing  Done*/}

                    <div
                      className="tab-pane fade"
                      id="withdrawExisting"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) =>
                                      Check_userAmount_to_wallet(e)
                                    }
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount (ETB) ex
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                                required
                                                onChange={
                                                  handleChangeWithdrawalExisting
                                                }
                                                value={
                                                  formDataWithdrawalExisting?.amount
                                                }
                                                // onChange={(e) => {
                                                //   e.target.value;
                                                // }}
                                                min={0}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Types of Saving
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              {/* <select
                                                name="type"
                                                required
                                                className="form-select  flex-grow-1"
                                                data-placeholder="Please Select"
                                                onChange={(e) =>
                                                  setTypeOfsavings(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select Saving Type
                                                </option>
                                                <option value="mandatory">
                                                  Mandatory
                                                </option>
                                                <option value="volantary">
                                                  Voluntary
                                                </option>
                                              </select> */}

                                              <select
                                                name="type"
                                                required
                                                value={
                                                  formDataWithdrawalExisting?.type
                                                }
                                                className="form-select  flex-grow-1"
                                                data-placeholder="Please Select"
                                                // onChange={(e) =>
                                                //   setTypeOfsavings(
                                                //     e.target.value
                                                //   )
                                                // }

                                                onChange={
                                                  handleChangeWithdrawalExisting
                                                }
                                              >
                                                <option
                                                  value=""
                                                  selected
                                                  disabled
                                                >
                                                  Please Select Unit
                                                </option>
                                                {savingTypes.map((option) => (
                                                  <option
                                                    key={option.type}
                                                    value={option._id}
                                                  >
                                                    {option.type}
                                                  </option>
                                                ))}{" "}

                                                {/* Add other loan types as needed */}
                                              </select>
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Interest Rate
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="interestrate"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Interest Rate"
                                              step="any"
                                              required
                                              value={
                                                formDataWithdrawalExisting?.interestrate ||
                                                ""
                                              }
                                              readOnly
                                            />
                                          </div>
                                        </div> */}

                                        <div className="row mt-5">
                                          <label className="col-lg-4 position-relative col-form-label required fw-semibold fs-6">
                                            Date
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="date"
                                              name="dateOfWithd"
                                              className="form-control position-relative form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Interest Rate"
                                              step="any"
                                              required
                                              max={
                                                new Date()
                                                  .toISOString()
                                                  .split("T")[0]
                                              }
                                              value={
                                                formDataWithdrawalExisting?.dateOfWithd
                                              }
                                              onChange={
                                                handleChangeWithdrawalExisting
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="Submit"
                                          // onClick={() =>
                                          //   DeleteVeriflyAfterSubmit()
                                          // }
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  {/* <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="all"
                                          onChange={(e) =>
                                            setwithdrawdata(e.target.value)
                                          }
                                          checked={withdrawdata == "all"}
                                          className="form-check-input"
                                        />
                                        All
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="mandatory"
                                          onChange={(e) =>
                                            setwithdrawdata(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Mandatory
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="voluntary"
                                          onChange={(e) =>
                                            setwithdrawdata(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Voluntary
                                      </label>
                                    </div>
                                  </div> */}

                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    {/* <div className="d-flex">
                                      <select
                                        name="marriageStatus"
                                        className="form-select  flex-grow-1"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                          setwithdrawdata(e.target.value)
                                        }
                                      >
                                        <option value={"all"}>All</option>
                                        <option value={"voluntary"}>
                                          Voluntary
                                        </option>
                                        <option value={"mandatory"}>
                                          Mandatory
                                        </option>
                                      </select>
                                    </div> */}
                                  </div>
                                </div>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="withdraw-table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  table="withdraw-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      {/* <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th> */}
                                      {/* <th className="min-w-100px">
                                        Intrest
                                        <br /> Rate{" "}
                                      </th> */}
                                      <th className="min-w-100px text-center">
                                        Status <br />
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Cancel <br />
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Dowanload Invoice <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {exwithdrawalHistory.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          {moment(item.activityDate).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item.totalAmount.toFixed(2)}</td>
                                        {/* <td>{item.type}</td> */}
                                        {/* <td>
                                          {item.remainingAmount.toFixed(2)}
                                        </td> */}
                                        <td>
                                          {item.status == "cancel" ? (
                                            <span className="text-danger">
                                              Cancelled
                                            </span>
                                          ) : (
                                            <span className="text-success">
                                              Success
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="Cancel"
                                            onClick={(e) => {
                                              paymentSendInProgressUser(item);
                                            }}
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <img
                                                src="/assets_new/images/cross.png"
                                                width="20px"
                                              />
                                            </span>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              wathdrawalExistinghandleDownloadInvoice(
                                                item.invoice
                                              )
                                            }
                                          >
                                            Download Invoice
                                          </button>
                                        </td>
                                        {/* <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                            <a
                                              href="digital_user_detail.html"
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={ExWithdpage}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handleExistPageClickpagewithd}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* savingtarget  done*/}

                    <div
                      className="tab-pane fade"
                      id="Savinginterest"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start gap-5">
                                <div className="row">
                                  {/* <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="mandatory"
                                          checked={
                                            intrestfilterawdata == "mandatory"
                                          }
                                          onChange={(e) =>
                                            setintrestfilterawdata(
                                              e.target.value
                                            )
                                          }
                                          className="form-check-input"
                                        />
                                        Mandatory
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="voluntary"
                                          onChange={(e) =>
                                            setintrestfilterawdata(
                                              e.target.value
                                            )
                                          }
                                          className="form-check-input"
                                        />
                                        Voluntary
                                      </label>
                                    </div>
                                  </div> */}

                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      {/* <select
                                        name="marriageStatus"
                                        className="form-select  flex-grow-1"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                          setintrestfilterawdata(e.target.value)
                                        }
                                      >
                                        <option value={"all"}>All</option>
                                        <option value={"voluntary"}>
                                          Voluntary
                                        </option>
                                        <option value={"mandatory"}>
                                          Mandatory
                                        </option>
                                      </select> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                    table="saving-table-to-xls"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText={
                                      <>
                                        <i className="fad fa-download fs-1"></i>
                                      </>
                                    }
                                  />
                                </div>
                              </h3>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="saving-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Month Date
                                      </th>
                                      <th className="min-w-150px">
                                        Total Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Interest Rate (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Interest Amount (ETB)
                                      </th>
                                      {/* <th className="min-w-100px">
                                        Intrest
                                        <br /> Rate{" "}
                                      </th> */}
                                      <th className="min-w-100px">
                                        Total Balance (ETB)
                                      </th>
                                      {/* <th className="min-w-100px">
                                        Cancel
                                      </th> */}
                                      <th className="min-w-100px">Actions</th>
                                    </tr>
                                  </thead>

                                  <tbody className="text-center">
                                    {interestData.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          {moment(item.date).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>
                                          {item.totalAmount || item?.amount}
                                        </td>
                                        <td>
                                          {item.totalInterest ||
                                            item?.interestRate}
                                        </td>
                                        <td>
                                          {item.totalInterestReceived.toFixed(
                                            2
                                          ) ||
                                            item?.totalInterestReceived.toFixed(
                                              2
                                            )}
                                        </td>
                                        <td>
                                          {item.balance != null
                                            ? item.balance.toFixed(2)
                                            : item.AmountWithInterestAmount !=
                                              null
                                              ? item.AmountWithInterestAmount.toFixed(
                                                2
                                              )
                                              : "0.00"}
                                        </td>

                                        <td className="text-center">
                                          <button
                                            onClick={() =>
                                              navigate(
                                                `/saving/detail/${item._id}`
                                              )
                                            }
                                            className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="View Detail"
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <i className="fad fa-eye fs-4"></i>
                                            </span>
                                          </button>
                                        </td>
                                        {/* <td>
                                          {item.remainingAmount.toFixed(2)}
                                        </td> */}

                                        {/* <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                            <a
                                              href="digital_user_detail.html"
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              {/* <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={SIpage}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={SavingPageClick}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Voluntary To Mandatory  done*/}

                    <div
                      className="tab-pane fade"
                      id="VoluntaryToMandatory"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) =>
                                      Check_userAmount_to_wallet(e)
                                    }
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-1">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount (ETB)
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                                required
                                                value={withAmount}
                                                onChange={(e) =>
                                                  setWithAmount(e.target.value)
                                                }
                                                min={0}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 mb-8">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Share Amount (ETB)
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="share am"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Amount"
                                              step="any"
                                              required
                                              value={
                                                (withAmount * SavingPrice) / 100
                                              }
                                              onChange={(e) =>
                                                setWithAmount(e.target.value)
                                              }
                                              min={0}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 mb-8">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Total Saving Amount (ETB)
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="saving am"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Amount"
                                              step="any"
                                              required
                                              value={
                                                withAmount -
                                                (withAmount * SavingPrice) / 100
                                              }
                                              onChange={(e) =>
                                                setWithAmount(e.target.value)
                                              }
                                              min={0}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 mb-8">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Current Share Price
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="sharePrice"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Amount"
                                              step="any"
                                              required
                                              value={SharePrice}
                                              readOnly
                                            // onChange={(e) => setWithAmount(e.target.value)}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="VoluntaryToMandatory-table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  table="VoluntaryToMandatory-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Share
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>

                                      <th className="min-w-150px">
                                        Status <br />
                                      </th>
                                      <th className="min-w-150px">
                                        Cancel <br />
                                      </th>
                                      {/* <th className="min-w-100px text-center">
                                        Invoice <br />
                                      </th> */}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {VoluntaryToMandatoryApiData.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          {moment(item.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item.totalAmount}</td>
                                        <td>{item.shareAmount}</td>
                                        <td>{item.to}</td>

                                        <td>
                                          {item.status == "cancel" ? (
                                            <span className="text-danger text-center">
                                              Cancelled
                                            </span>
                                          ) : (
                                            <span className="text-success text-center">
                                              Success
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          <button
                                            // onClick={(e) => {
                                            //   handledelete(item._id);
                                            // }}
                                            // onClick={(e) => {
                                            //   paymentSendInProgressUser(item);
                                            // }}
                                            onClick={(e) => {
                                              paymentSendInProgressUser(item);
                                            }}
                                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="Cancel"
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <img
                                                src="/assets_new/images/cross.png"
                                                width="20px"
                                              />
                                            </span>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              handleVoluntaryToMandatoryDownloadInvoice(
                                                item.invoice
                                              )
                                            }
                                          >
                                            Download Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              {/* <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                 PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={page}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Add exiting  Withdrawal  */}

                    <div
                      className="tab-pane fade"
                      id="Addexistingsavings"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) =>
                                      Check_userAmount_to_wallet(e)
                                    }
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                              Share Divided {shareDivided}
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="checkbox"
                                                name="isShareAllow"
                                                className="form-check-input"
                                                checked={shareDivided}
                                                onChange={(e) =>
                                                  setShareDivided(
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount (ETB)
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                                min={1}
                                                required
                                                value={withAmount}
                                                onChange={(e) =>
                                                  setWithAmount(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Types of Saving
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <select
                                                name="accType"
                                                required
                                                className="form-select flex-grow-1"
                                                data-placeholder="Please Select"
                                                value={TypeOfsavings}
                                                onChange={(e) =>
                                                  setTypeOfsavings(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  Select Saving Type
                                                </option>
                                                <option value="mandatory">
                                                  Mandatory
                                                </option>
                                                <option value="voluntary">
                                                  Voluntary
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div> */}

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Types of Saving
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <select
                                                name="typeId"
                                                required
                                                className="form-select  flex-grow-1"
                                                value={TypeOfsavings}
                                                data-placeholder="Please Select"
                                                onChange={
                                                  handleIntrestvalue
                                                }
                                              >
                                                {/* add saving lists */}
                                                <option
                                                  value=""
                                                  selected
                                                  disabled
                                                >
                                                  Please Select Saving
                                                </option>
                                                {savingTypes.map((option) => (
                                                  <option
                                                    key={option.type}
                                                    value={option._id}
                                                  >
                                                    {option.type}
                                                  </option>
                                                ))}{" "}
                                              </select>
                                            </div>
                                          </div>
                                        </div>

                                        {savingString === "mandatory" &&
                                          shareDivided && (
                                            <>
                                              <div className="col-lg-12 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                    Share Amount (ETB)
                                                  </label>
                                                  <div className="col-lg-8 fv-row">
                                                    <input
                                                      type="number"
                                                      name="shareAmount"
                                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                      placeholder="Amount"
                                                      required
                                                      step="any"
                                                      value={
                                                        (withAmount *
                                                          SavingPrice) /
                                                        100
                                                      }
                                                      onChange={(e) =>
                                                        setWithAmount(
                                                          e.target.value
                                                        )
                                                      }
                                                      min={0}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-12 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                    Total Saving Amount (ETB)
                                                  </label>
                                                  <div className="col-lg-8 fv-row">
                                                    <input
                                                      type="number"
                                                      name="amount"
                                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                      placeholder="Amount"
                                                      required
                                                      step="any"
                                                      value={
                                                        withAmount -
                                                        (withAmount *
                                                          SavingPrice) /
                                                        100
                                                      }
                                                      onChange={(e) =>
                                                        setWithAmount(
                                                          e.target.value
                                                        )
                                                      }
                                                      min={0}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Interest Rate
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="interest"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Interest Rate"
                                              step="any"
                                              required
                                              value={interestRate}
                                              onChange={(e) =>
                                                setInterestRate(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                              min={0}
                                              max={100}
                                            />
                                          </div>
                                        </div>

                                        <div className="row mt-6">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Enter Saving Date
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="date"
                                              name="date"
                                              id="date_timepicker_end"
                                              required
                                              max={
                                                new Date()
                                                  .toISOString()
                                                  .split("T")[0]
                                              }
                                              className="form-control form-control-lg form-control-solid position-relative"
                                              value={dateOfExisting}
                                              onChange={(e) =>
                                                setDateOfExisting(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="cardlabel fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      <select
                                        name="marriageStatus"
                                        className="form-select  flex-grow-1"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                          setfilterdata(e.target.value)
                                        }
                                      >
                                        <option value={"all"}>All</option>
                                        <option value={"voluntary"}>
                                          Voluntary
                                        </option>
                                        <option value={"mandatory"}>
                                          Mandatory
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Share
                                        <br />
                                        Amount (ETB)
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>
                                      <th className="min-w-100px">
                                        Interest
                                        <br /> Rate{" "}
                                      </th>
                                      {/* <th className="min-w-100px">Type</th> */}
                                      <th className="min-w-100px text-center">
                                        Status <br />
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Cancel <br />
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Invoice <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {savingsExistingHistory?.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          {moment(item.activityDate).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item.totalAmount.toFixed(2)}</td>
                                        <td>{item?.shareAmount || 0}</td>
                                        <td>
                                          {item.savingType == "mandatory"
                                            ? "Mandatory"
                                            : "Voluntary"}
                                        </td>
                                        <td>{item.interest.toFixed(2)}</td>
                                        {/* <td>
                                          {item.type == "deposite"
                                            ? "Deposit"
                                            : item.type}
                                        </td> */}
                                        <td>
                                          {item.status == "cancel" ? (
                                            <span className="text-danger">
                                              Cancelled
                                            </span>
                                          ) : (
                                            <span className="text-success">
                                              Success
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            // onClick={() => handleCancelTransaction(item._id)}
                                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="Cancel"
                                            onClick={(e) => {
                                              paymentSendInProgressUser(item);
                                            }}
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <img
                                                src="/assets_new/images/cross.png"
                                                width="20px"
                                              />
                                            </span>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              handleAddExistingSavingDownloadInvoice(
                                                item.invoice
                                              )
                                            }
                                          >
                                            Download Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handleExistPageClickpage}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* details modal  */}

          <Modal
            className="modal-center"
            show={Delethandler}
            // show={Candelhandler}
            onHide={() => setDelethandler(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyDelete}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Verify
                </Button>
              </Modal.Footer>
            </form>
          </Modal>

          {/* add saving modal */}
          <Modal
            className="modal-center"
            show={Candelhandler}
            // show={Candelhandler}
            onHide={() => setCancelhandler(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyCancel}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Verify
                </Button>
              </Modal.Footer>
            </form>
          </Modal>

          {/* voluntary to mandatory   */}

          <Modal
            className="modal-center"
            show={existingSavHandle}
            // show={Candelhandler}
            onHide={() => setExistingSavhandler(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyExistingSav}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Verify
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Savingcustomer;
