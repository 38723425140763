import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerLoader from "../CRM/Modal/CustomerLoder";
import debounce from "lodash.debounce";

const MyCart = ({ hadlersuper }) => {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const navigate = useNavigate();
  const { _id } = useParams();
  const [ProductDetails, setProductDetails] = useState([]);
  const [totalPayAmount, settotalAmount] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [slipImage, setCutsmerImage] = useState("");
  const [ShowLoader, setShowLoader] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);
  const [userinfo, setUserinfo] = useState([]);
  const [cartIds, setIds] = useState([]);
  const [show1, setShow1] = useState(false);
  const [Blockhandler, setBlockHandler] = useState(false);
  const [depositeBank, setDeposite] = useState("");
  const [addUnitshow, setShowAddUnit] = useState(false);
  const [productCartData, setProductCartData] = useState([]);
  const [relateUnitData, setRelatableUnitData] = useState([]);

  // const [orderId, setOrderId] = useState("");
  const [inputValue, setInputValue] = useState(0);

  const location = useLocation();
  const nonMemberData = location.state?.nonMemberData;
  let memberStatus = localStorage.getItem("isNonMember");
  const MakePayment = async (e) => {
    setShowLoader(true);
    e.preventDefault();
    let data = new FormData(e.target);
    let Formvlaues = Object.fromEntries(data.entries());
    let formData = Formvlaues;
    formData.slip = slipImage ? slipImage : "";
    formData.pay_method = depositeBank;
    formData.totalAmount = totalPayAmount;
    formData.custId = _id;
    formData.cartIds = cartIds;

    if (memberStatus) {
      formData.isNonMember = true;
      formData.nonMemberId = nonMemberData?._id;
    }

    axios
      .post(`/v1/super-Agent/check-out`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        if (data.status) {
          toast.success(data.msg);
          const orderDetailId = data.data;
          const ID = orderDetailId._id;
          localStorage.setItem("isNonMember", false);
          navigate(`/Invoice/${_id}/${ID}`);
          ProductList();
          setShow1(false);
          setShowLoader(false);
        } else {
          toast.error(data.msg);
        }
      });
  };

  const getRelatableUnits = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-Relatable-Units`,
        { unitId: productCartData?.salesUnitId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        setRelatableUnitData(data);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getRelatableUnits();
  }, [productCartData]);

  const addUnit = async (e) => {
    setShowLoader(true);
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios
      .post(
        `/v1/super-Agent/update-cart-product`,
        { ...formData, cartId: productCartData?._id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        if (data.status) {
          setShowAddUnit(false);
          ProductList();
          setShowLoader(false);
        } else {
          toast.error(data.msg);
          setShowLoader(false);
          ProductList();
          setShowAddUnit(false);
        }
      });
  };

  const handleFileChange = (data) => {
    let formdata = new FormData();
    // console.log("@#@#@123" + data);

    if (data) {
      const url = URL.createObjectURL(data);
      console.log("@#@#@" + url);
      setImageUrl(url);
      formdata.append("file", data);
      console.log("image first data", formdata);
      formdata.append("Image", data);
      console.log("image first data", formdata);
      axios
        .post(`/v1/DID/ImageUploader`, formdata, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const imgurl = res.data.data;
          console.log("image url", imgurl);
          setCutsmerImage(imgurl);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    } else {
      toast.error(data.msg);
    }
    // })
    // .catch(error => {
    //   console.error("Error checking face API:", error);
    // });
    // } else {

    // }
  };

  const ProductList = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/my-cart`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        let filter = data.data;
        const totalAmount = resp.data?.data?.totalAmount;
        console.log("-=-=-=-=-=-=-=-==-=-=->>>>>>>", filter);
        settotalAmount(totalAmount);
        setProductDetails(filter?.items);
        const idsArray = filter?.items.map((item) => item._id.toString());
        setIds(idsArray);
        setShowLoader(false);
      });
  };

  //-------------------------handle-verify---------------------------------------------//

  const VerfiyBlock = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockHandler(false);
      let data = response.data;

      if (data.status) {
        setShowLoader(false);
        setShow1(true);
      } else {
        toast.error(response.data.message);
        setShowPassword(false);
        setShowLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  //-----------------------handle-checkout----------------------------------------//

  const handleCheckOut = (productId) => {
    axios
      .post(
        `/v1/super-Agent/check-out`,
        { totalAmount: totalPayAmount, custId: _id, cartIds: cartIds },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        ProductList();
      })
      .catch();
  };

  //--------------------handle-delete-----------------------------------------------------

  const handleDelete = (productId) => {
    setShowLoader(true);
    axios
      .post(
        `/v1/super-Agent/remove-cart`,
        { productId: productId, custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        ProductList();
        setShowLoader(false);
      })
      .catch();
  };

  //----------------------------user-detail---------------------------------------------------------------------------------

  const customerDetails = () => {
    axios
      .post(
        `/v1/super-Agent/cust-detail `,
        { custID: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.Data;
        setUserinfo(data);

        console.log("data===== filter", data);
      });
  };

  const VerifyPassword = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;

      if (data.status) {
        setShowLoader(false);
      } else {
        toast.error(response.data.message);
        setShowPassword(false);
        setShowLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    hadlersuper();
    ProductList();
    customerDetails();
  }, []);

  const handleQuantityChange = debounce((item, change, type) => {
    if (!item?._id) return; // Ensure the product exists
    setShowLoader(true);
    const productId = item.productId;
    let updatedQuantity;

    // Determine the new quantity based on input type
    if (type === "text") {
      updatedQuantity = parseInt(change, 10);
      if (isNaN(updatedQuantity)) return;
    } else {
      updatedQuantity = (item.quantity || 0) + parseInt(change, 10);
    }

    // Prevent quantity from being less than 1
    if (updatedQuantity < 1) return;

    // Optimistic UI Update for an array structure
    setProductDetails((prevDetails) => {
      return prevDetails.map((product) =>
        product.productId === productId
          ? { ...product, quantity: updatedQuantity }
          : product
      );
    });

    // Prepare API payload
    const payload = {
      ...item,
      quantity: updatedQuantity,
      cartId: productCartData?._id,
    };

    // Track error display status
    let errorShown = false;

    axios
      .post(`/v1/super-Agent/update-cart-product`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.status) {
          toast.success("Quantity updated successfully");
          ProductList();
        } else {
          if (!errorShown) {
            toast.error(response.data.msg || "Error updating quantity");
            setTimeout(() => {
              errorShown = true; // Set error shown to true
            }, 20000);
          }
          ProductList();
        }
      })
      .catch((error) => {
        if (!errorShown) {
          toast.error("Something went wrong. Please try again.");
          setTimeout(() => {
            errorShown = true; // Set error shown to true
          }, 20000);
        }
        console.error(error);
      });
  }, 300);

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div className="card rounded-15 mb-12 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                    <div className="card-body  pt-9 pb-0 ">
                      <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <div className="me-2 mb-1">
                          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img src={userinfo?.IDphoto} alt="image" />
                            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                          </div>
                        </div>

                        <div className="flex-grow-1">
                          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                              <div className="d-flex align-items-center mb-2">
                                <a
                                  href="#"
                                  className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                >
                                  {userinfo?.fullname ||
                                    nonMemberData?.first_name +
                                      nonMemberData?.last_name}
                                </a>
                                <a href="#">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </span>
                                </a>
                              </div>

                              <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                <a
                                  href="#"
                                  className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                >
                                  <span className="svg-icon svg-icon-4 me-1">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                        fill="currentColor"
                                      />
                                      <rect
                                        x="7"
                                        y="6"
                                        width="4"
                                        height="4"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  {userinfo?.professoin}
                                </a>
                                <a
                                  href="#"
                                  className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                >
                                  <span className="svg-icon svg-icon-4 me-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  {userinfo?.nationality}
                                </a>
                                <a
                                  href="#"
                                  className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                >
                                  <span className="svg-icon svg-icon-4 me-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  {userinfo?.email}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex flex-wrap flex-stack">
                            <div className="d-flex flex-column flex-grow-1 pe-8">
                              <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-5 ">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-semibold fs-6 text-gray-400">
                                    Identity Trust Level
                                  </span>
                                  <span className="fw-bold fs-6">
                                    {userinfo?.proPercentage}%
                                  </span>
                                </div>
                                <div className="h-5px mx-3 w-100 bg-light mb-3">
                                  <div
                                    className="bg-success rounded h-5px "
                                    style={{
                                      width: `${userinfo?.proPercentage}%`,
                                    }}
                                    role="progressbar"
                                    aria-valuenow={userinfo?.proPercentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-title m-0 me-2">
                          <button
                            onClick={(e) => {
                              navigate(`/Strore/ManagementList/${_id}`);
                            }}
                            className="btn btn-primary btn-sm position-relative"
                            style={{
                              borderColor: "#28a745",
                              overflow: "visible",
                            }}
                          >
                            <span className="d-flex align-items-center">
                              <i className="fas fa-store me-1"></i> Go To Store
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <div id="kt_account_profile_details_form" className="form">
                      <div className="card-body border-top p-9">
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover">
                            <thead className="bg-primary text-white mb-5">
                              <tr>
                                <th className="text-center">Image</th>
                                <th>Name</th>
                                <th className="text-center">Quantity</th>
                                <th className="text-center">Price (ETB)</th>
                                <th className="text-center">Unit</th>
                                <th className="text-center">Total (ETB)</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ProductDetails?.length > 0 &&
                                ProductDetails?.map((item) => (
                                  <tr key={item._id}>
                                    <td className="text-center">
                                      <div className="symbol symbol-45px me-5">
                                        <img src={item.image} alt="Product" />
                                      </div>
                                    </td>
                                    <td>{item.productName}</td>
                                    <td className="text-center">
                                      <div className="input-group">
                                        <button
                                          className="btn btn-danger"
                                          type="button"
                                          onClick={() =>
                                            handleQuantityChange(item, -1)
                                          }
                                        >
                                          -
                                        </button>
                                        <input
                                          type="number"
                                          className="form-control text-center"
                                          min={1}
                                          value={item?.quantity}
                                          onChange={(e) =>
                                            handleQuantityChange(
                                              item,
                                              e.target.value,
                                              "text"
                                            )
                                          }
                                        />
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          onClick={() =>
                                            handleQuantityChange(item, 1)
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <input
                                        type="text"
                                        className="form-control text-center"
                                        readOnly
                                        value={`${item.pricePerKg}/${item.salesUnit}`}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <input
                                        type="text"
                                        className="form-control text-center"
                                        readOnly
                                        value={item.unitName}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        readOnly
                                        value={item.amount}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-icon btn-danger btn-sm me-1"
                                        title="Remove Product"
                                        type="button"
                                        onClick={() =>
                                          handleDelete(item.productId)
                                        }
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                      <button
                                        className="btn btn-icon btn-sm me-1"
                                        title="Edit"
                                        type="button"
                                        onClick={() => {
                                          setShowAddUnit(true);
                                          setProductCartData(item);
                                        }}
                                      >
                                        <i className="fas fa-edit"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="card-header border-bottom-0 d-flex justify-content-between align-items-center">
                        <h3 className="fw-bold text-primary fs-3 m-0">
                          Total (ETB): {totalPayAmount}
                        </h3>
                        {ProductDetails?.length > 0 && (
                          <button
                            onClick={() => setBlockHandler(true)}
                            type="button"
                            className="btn btn-primary btn-lg"
                          >
                            Check Out
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-center"
        show={ShowPassword}
        onHide={() => setShowPassword(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerifyPassword}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              Verify
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        className="modal-center"
        show={Blockhandler}
        onHide={() => setBlockHandler(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerfiyBlock}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={show1}
        onHide={() => setShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Payment</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => MakePayment(e)}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mt-3 ">
                <label className=" fs-6">
                  <span className="">Total (ETB)</span>
                </label>

                <input
                  type="number"
                  name="interestRate"
                  defaultValue={totalPayAmount}
                  pattern="[0-9]{10}"
                  title=" Only number allow  Ten digits code"
                  required
                  readOnly
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Interest Rate"
                  step="any"
                />
              </div>

              <div className="col-lg-6 mt-3 ">
                <label className=" fs-6">
                  <span className=""> Payment Method</span>
                </label>

                <select
                  name="pay_method"
                  required
                  className="form-select  flex-grow-1"
                  // data-control="select2"
                  data-placeholder="Please Select"
                  onChange={(e) => setDeposite(e.target.value)}
                >
                  <option value="">Choose the Pay Method</option>

                  <option value={"Cash"}>Cash</option>
                  <option value={"BackDeposit"}>Bank Deposit</option>
                </select>
              </div>
            </div>

            {/* <div className="col-lg-12 mb-8">
                            <div className="row">
                                <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                    Payment Method
                                </label>
                                <div className="col-lg-7 fv-row">
                                    <select
                                        name="pay_method"
                                        required
                                        className="form-select  flex-grow-1"
                                        // data-control="select2"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                            setDeposite(e.target.value)
                                        }
                                    >
                                        <option value="">
                                            Choose the Pay Method
                                        </option>

                                        <option value={"Cash"}>
                                            Cash
                                        </option>
                                        <option value={"BackDeposit"}>
                                            Bank Deposit
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div> */}

            {depositeBank === "BackDeposit" ? (
              <div className="row mt-5">
                <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                  Deposit Slip
                </label>
                <div className="col-lg-7 fv-row">
                  <input
                    type="file"
                    name="depositSlip"
                    accept=".jpg, .jpeg, .png, .pdf"
                    className="form-control"
                    required
                    onChange={(e) => handleFileChange(e.target.files[0])}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {" "}
              Make Payment
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={addUnitshow} onHide={() => setShowAddUnit(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Update Data </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => addUnit(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="mb-3">
                <label className="mb-2 bt-2">Product Name</label>
                <input
                  className="form-control"
                  placeholder="Enter Code"
                  type="productName"
                  name="Product Name"
                  readOnly
                  defaultValue={productCartData?.productName}
                />
              </div>
              <div className="mb-3">
                <label className="mb-2 bt-2">Quantity</label>
                <input
                  className="form-control"
                  placeholder="Enter Name"
                  type="number"
                  step={0.1}
                  name="quantity"
                  min={0}
                  defaultValue={productCartData?.Quantity}
                />
              </div>
              <div className="mb-3">
                <label className="mb-2 bt-2">Unit</label>
                <div className="col">
                  <select
                    className="form-select  flex-grow-1"
                    // data-control="select2"
                    data-placeholder="Please Select Category"
                    data-hide-search="true"
                    name="unitId"
                    //   onChange={handleSalesUnitChange}
                    required
                  >
                    <option value="" selected disabled>
                      Please Select Unit
                    </option>
                    {relateUnitData?.map((option) => (
                      <option key={option.name} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddUnit(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              // onClick={() => formhandle()}
            >
              {" "}
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default MyCart;
