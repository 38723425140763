import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import "../assets/css/style.css";

const TransactionsInvoice = ({ hadlersuper }) => {
  const location = useLocation();
  const invoiceState = location.state;
  console.log({ invoiceState });
  useEffect(() => {
    hadlersuper();
  }, [location]);

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Header Section
    doc.text("Invoice", 105, 20, { align: "center" });
    doc.text(
      `Invoice Date: ${moment(invoiceState?.orderDate).format("DD/MM/YYYY")}`,
      14,
      40
    );

    // User Information (Top-Left)
    doc.setFontSize(12);
    doc.text("Details:", 14, 60);
    doc.text(
      `Name: ${invoiceState?.customers?.fullname || "Customer Name"}`,
      14,
      70
    );
    doc.text(`Email: ${invoiceState?.customers?.email || "N/A"}`, 14, 80);
    doc.text(`Phone: ${invoiceState?.customers?.phone || "N/A"}`, 14, 90);
    doc.text(`Address: ${invoiceState?.customers?.address || "N/A"}`, 14, 100);
    doc.text(`City: ${invoiceState?.customers?.city || "N/A"}`, 14, 110);

    // Payment Information (Top-Right)
    const paymentStartX = 120;
    doc.text("Payment Method:", paymentStartX, 60);
    doc.text(
      `Transaction Ref.: ${
        invoiceState?.customers?.digitalrefID || invoiceState?._id || "N/A"
      }`,
      paymentStartX,
      70
    );
    doc.text(
      `Method: ${invoiceState?.accountBalance || "Not Available"}`,
      paymentStartX,
      80
    );
    doc.text(
      `Invoice: ${invoiceState?.TypeByInvoice || "Not Available"}`,
      paymentStartX,
      90
    );

    // Loan or Share Details (Below)
    const bodyData = [
      [
        "Amount",
        invoiceState?.loanAmount ||
          invoiceState?.amount ||
          invoiceState?.totalAmount ||
          0,
      ],
      ["Interest Rate", `${invoiceState?.interestRate || 0}%`],
      [
        "Interest Amount ",
        `${
          invoiceState?.totalInterestAmount ||
          invoiceState?.totalInterestReceived?.toFixed(2) ||
          0
        }`,
      ],
    ];

    if (invoiceState?.TypeByInvoice === "Loans") {
      bodyData.push(
        ["Repayment Schedule", invoiceState?.repaymentSchedule || "N/A"],
        ["Loan Duration", `${invoiceState?.loanDuration || 0} Months`],
        ["Total Interest Payable", invoiceState?.totalInterestPay || 0],
        ["Remaining Amount", invoiceState?.remainingAmount || 0]
      );
    } else {
      bodyData.push(
        [
          "Share Price",
          invoiceState?.invoice?.shareAmount ||
            invoiceState?.invoice?.sharePrice ||
            invoiceState?.sharePrice ||
            0,
        ],
        [
          "Share Quantity",
          invoiceState?.invoice?.quantity || invoiceState?.quantity || 0,
        ]
      );
    }

    bodyData.push([
      "Approval Date",
      moment(invoiceState?.approvedDate).format("DD/MM/YYYY"),
    ]);

    doc.autoTable({
      startY: 120,
      head: [["Field", "Value"]],
      body: bodyData,
    });

    doc.save("invoice.pdf");
  };

  const handlePrint = () => {
    window.print();
  };

  const downloadInvoice = () => {
    html2canvas(document.getElementById("invoice_wrapper"), {
      useCORS: true,
    }).then((canvas) => {
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      const pageHeight = (contentHeight / contentWidth) * 595.28; // A4 height in points

      const pdf = new jsPDF("p", "pt", "a4");

      // Convert the canvas image to a data URL and include it in the PDF
      const imgData = canvas.toDataURL("image/png");

      // Add the image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, 595.28, pageHeight);

      // Save the PDF
      pdf.save("invoice.pdf");
    });
  };

  return (
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="invoice-2 invoice-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="invoice-inner clearfix">
                  <div className="invoice-info clearfix" id="invoice_wrapper">
                    <div class="invoice-headar">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="invoice-logo">
                            <div class="logo">
                              <img
                                src="/assets/img/logos/logo.png"
                                alt="logo"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="invoice-id">
                            <div class="info">
                              <h1 class="inv-header-1">Invoice</h1>

                              <p className="mb-0">
                                <strong>Invoice Date:</strong>{" "}
                                {moment(invoiceState?.orderDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-4">
                      <div className="card-header border-bottom p-4">
                        <div className="d-flex ">
                          <div className="mt-sm-5 mt-4 col-9">
                            <h6 className="text-muted text-uppercase fw-semibold">
                              Address
                            </h6>
                            <p className="text-muted mb-1" id="address-details">
                              Ethiopia{" "}
                            </p>
                            <p className="text-muted mb-0" id="zip-code">
                              <span>Zip-code:</span> 90201
                            </p>
                          </div>

                          <div className="flex-shrink-0 mt-sm-0 mt-3 col-3">
                            <h6>
                              <span className="text-muted fw-normal">
                                Invoice No:
                              </span>
                              <span id="legal-register-no">
                                {invoiceState?.customers?.digitalrefID}
                              </span>
                            </h6>
                            <h6>
                              <span className="text-muted fw-normal">
                                Email:
                              </span>
                              <span id="email">
                                {invoiceState?.customers?.email || "N/A"}{" "}
                              </span>
                            </h6>

                            <h6 className="mb-0">
                              <span className="text-muted fw-normal">
                                Contact No:{" "}
                              </span>
                              <span id="contact-no">
                                {" "}
                                {invoiceState?.customers?.phone || "N/A"}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card-body p-4">
                            <div className="row g-3">
                              <div className="col-lg-3 col-6">
                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                  Invoice No
                                </p>
                                <h5 className="fs-14 mb-0">
                                  <span id="invoice-no">
                                    {" "}
                                    {invoiceState?.customers?.digitalrefID}
                                  </span>
                                </h5>
                              </div>
                              {/*end col*/}
                              <div className="col-lg-3 col-6">
                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                  Date
                                </p>
                                <h5 className="fs-14 mb-0">
                                  <span id="invoice-date">
                                    {" "}
                                    {moment(invoiceState?.orderDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>{" "}
                                </h5>
                              </div>
                              {/*end col*/}
                              <div className="col-lg-3 col-6">
                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                  Payment Type
                                </p>
                                <h5 className="fs-14 mb-0">
                                  <span
                                    className=" bg-success-subtle  fs-11"
                                    id="payment-status"
                                  >
                                    {invoiceState?.accountBalance}
                                  </span>
                                </h5>
                              </div>
                              {/*end col*/}
                              <div className="col-lg-3 col-6">
                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                  Total Amount
                                </p>
                                <h5 className="fs-14 mb-0">
                                  <span id="total-amount">
                                    {" "}
                                    {invoiceState?.loanAmount ||
                                      invoiceState?.amount ||
                                      invoiceState?.totalAmount}
                                  </span>
                                </h5>
                              </div>
                              {/*end col*/}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end card-body*/}
                        </div>
                        <div className="col-lg-12">
                          <div className="card-body p-4 border-top border-top-dashed">
                            <div className="row g-3">
                              <div className="col-6">
                                <h4 className="inv-title-1">Invoice To</h4>

                                <p className="fw-medium mb-2" id="billing-name">
                                  {invoiceState?.customers?.fullname ||
                                    "Customer Name"}{" "}
                                </p>

                                <p className="text-muted mb-1">
                                  <span>Phone: +</span>
                                  <span id="billing-phone-no">
                                    {invoiceState?.customers?.phone || "N/A"}
                                  </span>
                                </p>
                                <p className="text-muted mb-0">
                                  <span>Address: </span>
                                  <span id="billing-tax-no">
                                    {invoiceState?.customers?.address || "N/A"}
                                  </span>{" "}
                                </p>
                                <p className="text-muted mb-0">
                                  <span>City: </span>
                                  <span id="billing-tax-no">
                                    {invoiceState?.customers?.city || "N/A"}
                                  </span>{" "}
                                </p>
                              </div>
                              {/*end col*/}
                              <div className="col-6">
                                <h6 className="text-muted  fw-semibold mb-3">
                                  <h4 className="inv-title-1">Invoice From</h4>
                                </h6>
                                <p
                                  className="fw-medium mb-2"
                                  id="shipping-name"
                                >
                                  {`${
                                    invoiceState?.superAgentDetail?.fullName ||
                                    ""
                                  } `}
                                </p>

                                <p className="text-muted mb-1">
                                  <span>Email: +</span>
                                  <span id="shipping-phone-no">
                                    {invoiceState?.email || "N/A"}
                                  </span>
                                </p>
                                <p className="text-muted mb-1">
                                  <span>Address: </span>
                                  <span id="shipping-phone-no">
                                    {invoiceState?.homeAddress || "N/A"}
                                  </span>
                                </p>
                              </div>
                              {/*end col*/}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end card-body*/}
                        </div>
                      </div>

                      <div className="px-4">
                        <div className="invoice-details table-responsive mt-4">
                          <h4 className="inv-title-1 text-capitalize">
                            {invoiceState?.TypeByInvoice} Details
                          </h4>
                          <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                            <thead>
                              <tr className="table-active">
                                <th>Field</th>
                                <th>Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-muted "> Amount</td>
                                <td className="text-muted ">
                                  {invoiceState?.loanAmount ||
                                    invoiceState?.amount ||
                                    invoiceState?.totalAmount}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-muted ">Interest Rate</td>
                                <td className="text-muted ">
                                  {invoiceState?.interest ||
                                    invoiceState?.invoice?.interest ||
                                    0}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-muted ">Interest Amount</td>
                                <td className="text-muted ">
                                  {" "}
                                  {invoiceState?.totalInterestAmount ||
                                    invoiceState?.totalInterestReceived?.toFixed(
                                      2
                                    ) ||
                                    0}
                                </td>
                              </tr>

                              {/* loan field  start */}
                              {invoiceState?.TypeByInvoice == "Loans" ? (
                                <>
                                  <tr>
                                    <td className="text-muted ">
                                      Repayment Schedule
                                    </td>
                                    <td className="text-muted ">
                                      {invoiceState?.repaymentSchedule || "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-muted ">
                                      Loan Duration
                                    </td>
                                    <td className="text-muted ">
                                      {invoiceState?.loanDuration || 0} Months
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-muted ">
                                      Total Interest Payable
                                    </td>
                                    <td className="text-muted ">
                                      {invoiceState?.totalInterestPay || 0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-muted ">
                                      Remaining Amount
                                    </td>
                                    <td className="text-muted ">
                                      {invoiceState?.remainingAmount || 0}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                  {/* share filed start  */}

                                  <tr>
                                    <td className="text-muted ">Share Price</td>
                                    <td className="text-muted ">
                                      {invoiceState?.invoice?.shareAmount ||
                                        invoiceState?.invoice?.sharePrice ||
                                        invoiceState?.sharePrice ||
                                        0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-muted ">
                                      {" "}
                                      Share Quantity
                                    </td>
                                    <td className="text-muted ">
                                      {invoiceState?.invoice?.quantity ||
                                        invoiceState?.quantity ||
                                        0}
                                    </td>
                                  </tr>
                                  {/* share filed end  */}
                                </>
                              )}

                              {/* loan field  end*/}

                              <tr>
                                <td className="text-muted ">Approval Date</td>
                                <td className="text-muted ">
                                  {moment(invoiceState?.approvedDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <div class="row ">
                          <div class="col-lg-6 col-md-5 col-sm-5 mt-4">
                            <div class="payment-method mb-30">
                              <h3 class="inv-title-1">Payment Method</h3>
                              <ul class="payment-method-list-1 text-14">
                                <li>
                                  <strong>Transaction Ref.:</strong>{" "}
                                  {invoiceState?.customers?.digitalrefID}
                                </li>
                                <li>
                                  <strong>Method:</strong>{" "}
                                  {invoiceState?.accountBalance}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div class="invoice-btn-section clearfix d-print-none">
                      <a
                        href="javascript:window.print()"
                        class="btn btn-lg  pt-0 btn-print"
                        onClick={handlePrint}
                      >
                        <i class="fa fa-print"></i> Print Invoice
                      </a>
                      <a
                        id="invoice_download_btn"
                        class="btn btn-lg btn-download  pt-0 btn-theme"
                        onClick={downloadPDF}
                      >
                        <i class="fa fa-download"></i> Download Invoice
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsInvoice;
