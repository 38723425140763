import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../assets/css/style.css';
const BuyingInvoice = ({hadlersuper}) => {
    let token = localStorage.getItem("SuperAgentChromepayToken");
    const { _id } = useParams();
    // const { custId } = useParams();
    const [ProductDetails, setProductDetails] = useState([]);
    const [totalPayAmount, settotalAmount] = useState("");
    const [userInfo, setUserinfo] = useState("");
    const [payMethod, setPayMethod] = useState("")
    const [transaNo, setTransfNo] = useState("")
    const [orderDate, setOrderDate] = useState("")
    const [superAgentDetail, setSuperAgentDetail] = useState("")






    // manage Product Purchase Data
    const ProductList = async () => {
        await axios
            .post(
                `/v1/super-Agent/product-detail`,
                { productId: _id },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp.data;
                let filter = data.data;
                setPayMethod("cash")
                setTransfNo(data?._id)
                setOrderDate(data?.order_date)
                setSuperAgentDetail(data?.findAgent)
                console.log("Invoice data", data)
                setProductDetails(filter);
                const totalPrice = filter.reduce((total, item) => total + Number(item.amount), 0);
                console.log({ totalPrice })
                settotalAmount(filter[0]?.pricePerKg * filter[0]?.availableQuantity)
                console.log("---->>>",{filter})
            });
    };

    // Manage Customer Detail

    const custumerDetails = () => {
        axios
            .post(
                `/v1/super-Agent/cust-detail `,
                { custID: _id },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp.data.Data;
                setUserinfo(data);

                console.log("data===== filter", data);
            });
    };


    


    useEffect(() => {
        custumerDetails();
        ProductList();
        hadlersuper();

    }, []);

    const [pdfGenerated, setPdfGenerated] = useState(false);

    const generatePDF = () => {
        const invoiceWrapper = document.getElementById('invoice_wrapper');
    
        try {
            html2canvas(invoiceWrapper, { allowTaint: true }).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg", 1.0);
                const pdf = new jsPDF('p', 'pt', 'a4');
                pdf.addImage(imgData, 'JPEG', 0, 0, 595, 842); // A4 size: 595 x 842 pts
                pdf.save("sample-invoice.pdf");
                setPdfGenerated(true);
            });
        } catch (error) {
            console.error("Error generating PDF:", error);
            // Handle error as needed, e.g., show an error message to the user
        }
    };
    

    


    return (

        <>

<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="invoice-2 invoice-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="invoice-inner clearfix">
                                <div className="invoice-info clearfix" id="invoice_wrapper">
                                    <div className="invoice-headar">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="invoice-logo">

                                                    <div className="logo">
                                                        {superAgentDetail?.image}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="invoice-id">
                                                    <div className="info">
                                                        <h1 className="inv-header-1">Invoice</h1>
                                                        <p className="mb-1">Invoice Number: <span>{transaNo}</span></p>
                                                        <p className="mb-0">Invoice Date: <span>{moment(orderDate).format("DD/MM/YYYY")}</span></p>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-top">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="invoice-number mb-30">
                                                    <h4 className="inv-title-1">Invoice To</h4>
                                                    <h2 className="name">{userInfo?.fullname}</h2>
                                                    <p className="invo-addr-1">
                                                        {/* Theme Vessel  */}
                                                        <br />
                                                        {userInfo?.email || userInfo?.phone} <br />
                                                        {userInfo?.address} <br />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="invoice-number mb-30">
                                                    <div className="invoice-number-inner">
                                                        <h4 className="inv-title-1">Invoice From</h4>
                                                        <h2 className="name">{superAgentDetail?.firstName || "" + superAgentDetail?.lastName || "" }</h2>
                                                        <p className="invo-addr-1">
                                                            <br />
                                                            {superAgentDetail?.email} <br />
                                                            {superAgentDetail?.address  } <br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-center">
                                        <div className="table-responsive">
                                            <table className="table mb-0 table-striped invoice-table">
                                                <thead className="bg-active">
                                                    <tr className="tr">
                                                        <th>No.</th>
                                                        <th className="pl0 text-start"> Image</th>
                                                        <th className="pl0 text-start"> Name</th>
                                                        <th className="text-center">Price</th>
                                                        <th className="text-center">Quantity</th>
                                                        <th className="text-center">Unit</th>
                                                        <th className="text-end">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ProductDetails.map(item => (
                                                        <tr className="tr">
                                                            <td>
                                                                <div className="item-desc-1">
                                                                    <span>01</span>
                                                                </div>
                                                            </td>
                                                            <img style={{ height: "100%", width: "100%", maxWidth: "100px", maxHeight: "100px" }} src={item.image} alt="" />

                                                            <td className="pl0">{item?.productName}</td>
                                                            <td className="text-center">{item?.pricePerKg}</td>
                                                            <td className="text-center">{item?.availableQuantity}</td>
                                                            <td className="text-center">{item?.unit || 'kg'}</td>
                                                            <td className="text-end">{(item?.pricePerKg) * (item?.availableQuantity)}</td>
                                                        </tr>
                                                    ))}

                                                    <tr className="tr2">
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-center">Tax (ETB)</td>
                                                        <td className="text-end">0</td>
                                                    </tr>
                                                    <tr className="tr2">
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-center f-w-900 active-color" style={{ fontWeight: "bold" }}>Grand Total (ETB)</td>
                                                        <td className="text-end active-color text-bold" style={{ fontWeight: "bold" }}>{totalPayAmount}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="invoice-bottom">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-5 col-sm-5">
                                                <div className="payment-method mb-30">
                                                    <h3 className="inv-title-1">Payment Method</h3>
                                                    <ul className="payment-method-list-1 text-14">
                                                        <li><strong>Transaction Ref.:</strong> {transaNo}</li>
                                                        <li><strong>Method:</strong>{payMethod}</li>
                                                        <li><strong>Branch Name:</strong>Not Available</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 col-md-7 col-sm-7">
                                                <div className="terms-conditions mb-30">
                                                    <h3 className="inv-title-1">Terms & Conditions</h3>
                                                    <p>Once the product has been delivered and payment has been completed, our return policy is no longer applicable. Please ensure that all criteria are identified before placing an order.</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="invoice-contact clearfix">
                                        <div className="row g-0">
                                            <div className="col-sm-12">
                                                <div className="contact-info clearfix">
                                                    <a href="tel:+55-4XX-634-7071" className="d-flex"><i className="fa fa-phone"></i> {superAgentDetail?.phone}</a>
                                                    <a href="tel:info@themevessel.com" className="d-flex"><i className="fa fa-envelope"></i>{superAgentDetail?.email}</a>
                                                    <a href="tel:info@themevessel.com" className="mr-0 d-flex d-none-580"><i className="fa fa-map-marker"></i>{superAgentDetail?.address} </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-btn-section clearfix d-print-none">
                                    <a href="javascript:window.print()" className="btn btn-lg  pt-0 btn-print">
                                        <i className="fa fa-print"></i> Print Invoice
                                    </a>
                                    <a id="invoice_download_btn"  onClick={generatePDF} className="btn btn-lg btn-download  pt-0 btn-theme">
                                        <i className="fa fa-download"></i> Download Invoice
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>

        </>





    )
}


export default BuyingInvoice