

import React, { useState, useEffect } from "react";
import axios from "axios";

import { Line } from "react-chartjs-2";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { backend } from "blink-detection";
var jwt = require("jsonwebtoken");

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const TransactionSuperReports = ({hadlersuper}) =>  {
    let token = localStorage.getItem("SuperAgentChromepayToken");
   var decode1 = jwt.decode(token);
   let _id = decode1.OrganisationID


   const [Today_date, setToday_date] = useState([]);
   const [Last_Month, setLast_Month] = useState([]);
   const [useractivedata, setuseractivedata] = useState([]);
   const [useractivedatayear, setuseractivedatayear] = useState([]);
   const [useractivedatamonths, setuseractivedatamonths] = useState([]);
   const [useractiveSavingdatayear, setuseractiveSavingdatayear] = useState([]);
   const [useractiveSavingdatamonths, setuseractiveSavingdatamonths] = useState([]);

   const [useractiveStoredatayear, setuseractiveStoredatayear] = useState([]);
   const [useractiveStoredatamonths, setuseractiveStoredatamonths] = useState([]);
   const [section, setSection] = useState([]);
   const [dash, setDash] = useState([]);
   const [frequency, setFrequency] = useState("month")
   const [savingTypeFrequency, setsavingTypeFrequency] = useState("mandatory")
   const [savingFrequency, setSavingFrequency] = useState("month")
   const [storeFrequency, setStoreFrequency] = useState("month")
   const orgID = _id;



   const UserPermoance = async () => {
       await axios.post(`/v1/super-Agent/superagent-report`, {}, { headers: { "Authorization": `Bearer ${token}` } })
       .then((res) => {
           const userData3 = res.data;
           const userData = res.data.detail;
            setuseractivedata(userData3);
           setDash(userData);
           console.log("++++++++++++++12", userData);
          
       });
   };

   const orgTransectionsSection = async () => {
       await axios.post(`/v1/org/reports/org-detail`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
           const data = resp.data.data;
           // console.log("123", data);
           setSection(data);

       });
   };


   useEffect(() => {
       UserPermoance();
       // UserPermoanceyear();
       orgTransectionsSection();
       // setTimeout(() => {

       // }, 1000);

       hadlersuper();

   }, []);
   // console.log("123");
   // console.log("test", section);
   const transactionGraph = async (sort) => {
       const body = {  Type: frequency }
       await axios.post(`/v1/super-Agent/super-agent-graph`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
         const userData = res.data.data;
   
       //    console.log("++++++++++++++123", userData);
         const categories = userData.map((item) => item.count);
         const users = userData.map((item) => item.value);
   
         setuseractivedatayear(categories);
         setuseractivedatamonths(users);
         // console.log("$$$", categories)
         // setTranscation(data);
         // setuseractivedatayear(Object.values(userData));
   
         // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
       });
     };




     const savingTransactionGraph = async (sort) => {
        const body = {  Type: savingFrequency, savingType : savingTypeFrequency }
        await axios.post(`/v1/super-Agent/saving-trans-graph`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
          const userData = res.data.data;
    
        //    console.log("++++++++++++++123", userData);
          const categories = userData.map((item) => item.count);
          const users = userData.map((item) => item.value);
    
          setuseractiveSavingdatayear(categories);
          setuseractiveSavingdatamonths(users);
          // console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
    
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
      };


      const storeTransactionGraph = async (sort) => {
        const body = {  Type: storeFrequency }
        await axios.post(`/v1/super-Agent/store-trans-graph`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
          const userData = res.data.data;
    
        //    console.log("++++++++++++++123", userData);
          const categories = userData.map((item) => item.count);
          const users = userData.map((item) => item.value);
    
          setuseractiveStoredatayear(categories);
          setuseractiveStoredatamonths(users);
          // console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
    
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
      };
   // const datafilter = (e) => {

   //     e.preventDefault();
   //     const value = e.target.value;


   //     axios.post(`/v1/org/reports/get_org_cust_data_graph`, { filter: value }, { headers: { "Authorization": `bearer ${token}` } }).then((res) => {
   //         var userData = res.data.obj;

   //         console.log("++++++++++++++graph data", userData);

   //         setuseractivedatayear(Object.values(userData));
   //         setuseractivedatamonths(Object.keys(userData));
   //     });
   // };




   // console.log('====================================');
   // console.log(section);
   // console.log('====================================');

   useEffect(() => {
       transactionGraph();
     }, [frequency])
     
   useEffect(() => {
       savingTransactionGraph()
     }, [savingFrequency])

   useEffect(() => {
       savingTransactionGraph()
     }, [savingTypeFrequency])

   useEffect(() => {
    storeTransactionGraph()
     }, [storeFrequency])

     const handlesorting = (event) => {
       let sort = event.target.value;
       setFrequency(sort);
       transactionGraph(sort);
       console.log(sort)
   
     }
     const handleSavingsorting = (event) => {
        let sort = event.target.value;
        setSavingFrequency(sort);
        savingTransactionGraph(sort);
        console.log(sort)
    
      }
     const handleSavingTypesorting = (event) => {
        let sort = event.target.value;
        setsavingTypeFrequency(sort);
        savingTransactionGraph(sort);
        console.log(sort)
    
      }
     const handleStoreTypesorting = (event) => {
        let sort = event.target.value;
        setStoreFrequency(sort);
        storeTransactionGraph(sort);
        console.log(sort)
    
      }
   return (
       <>

           <div
               className="wrapper d-flex flex-column flex-row-fluid mb-5 back-color"
               id="kt_wrapper"

           >
               <div
                   className="content d-flex flex-column flex-column-fluid mb-0 py-0"
                   id="kt_content"
               >
                   <div className="container-xxl mb-0" id="kt_content_container">
                       <div className="card-body  pt-9 pb-0 ">
                           <div className="row">
                               <div className="col-lg-6">
                                   <div className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover" style={{ paddingBottom: "1.5rem" }}>
                                       <div className="card-body  pt-9 pb-0 ">
                                           <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                               <div className="me-7 mb-4">
                                                   <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                       <img src={dash.Image ||
                                      "/assets_new/images/lodingwallet.gif"} alt="image" />
                                                       <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                                   </div>
                                               </div>

                                               <div className="flex-grow-1">
                                                   <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                       <div className="d-flex flex-column">
                                                           <div className="d-flex align-items-center mb-2">
                                                               <a
                                                                   href="#"
                                                                   className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                                               >
                                                                   {dash.firstName} {dash.lastName}
                                                               </a>
                                                               <a href="#">
                                                                   <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                       <svg
                                                                           xmlns="http://www.w3.org/2000/svg"
                                                                           width="24px"
                                                                           height="24px"
                                                                           viewBox="0 0 24 24"
                                                                       >
                                                                           <path
                                                                               d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                                                                               fill="currentColor"
                                                                           />
                                                                           <path
                                                                               d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                                                                               fill="white"
                                                                           />
                                                                       </svg>
                                                                   </span>
                                                               </a>
                                                           </div>

                                                           <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                               <a
                                                                   href="#"
                                                                   className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                               >
                                                                   <span className="svg-icon svg-icon-4 me-1">
                                                                       <svg
                                                                           width="18"
                                                                           height="18"
                                                                           viewBox="0 0 18 18"
                                                                           fill="none"
                                                                           xmlns="http://www.w3.org/2000/svg"
                                                                       >
                                                                           <path
                                                                               opacity="0.3"
                                                                               d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                                                               fill="currentColor"
                                                                           />
                                                                           <path
                                                                               d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                                                               fill="currentColor"
                                                                           />
                                                                           <rect
                                                                               x="7"
                                                                               y="6"
                                                                               width="4"
                                                                               height="4"
                                                                               rx="2"
                                                                               fill="currentColor"
                                                                           />
                                                                       </svg>
                                                                   </span>
                                                                   Super Agent
                                                               </a>
                                                               <a
                                                                   href="#"
                                                                   className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                               >
                                                                   <span className="svg-icon svg-icon-4 me-1">
                                                                       <svg
                                                                           width="24"
                                                                           height="24"
                                                                           viewBox="0 0 24 24"
                                                                           fill="none"
                                                                           xmlns="http://www.w3.org/2000/svg"
                                                                       >
                                                                           <path
                                                                               opacity="0.3"
                                                                               d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                                               fill="currentColor"
                                                                           />
                                                                           <path
                                                                               d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                                               fill="currentColor"
                                                                           />
                                                                       </svg>
                                                                   </span>
                                                                   {dash.country}
                                                               </a>
                                                               <a
                                                                   href="#"
                                                                   className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                                               >
                                                                   <span className="svg-icon svg-icon-4 me-1">
                                                                       <svg
                                                                           width="24"
                                                                           height="24"
                                                                           viewBox="0 0 24 24"
                                                                           fill="none"
                                                                           xmlns="http://www.w3.org/2000/svg"
                                                                       >
                                                                           <path
                                                                               opacity="0.3"
                                                                               d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                                               fill="currentColor"
                                                                           />
                                                                           <path
                                                                               d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                                               fill="currentColor"
                                                                           />
                                                                       </svg>
                                                                   </span>
                                                                   {dash.email}
                                                               </a>
                                                           </div>
                                                       </div>
                                                   </div>

                                                   <div className="d-flex flex-wrap flex-stack">
                                                       <div className="d-flex flex-column flex-grow-1 pe-8">
                                                           <div className="d-flex flex-wrap">
                                                               <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                   <div className="d-flex align-items-center">
                                                                       <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                                           <svg
                                                                               width="24"
                                                                               height="24"
                                                                               viewBox="0 0 24 24"
                                                                               fill="none"
                                                                               xmlns="http://www.w3.org/2000/svg"
                                                                           >
                                                                               <rect
                                                                                   opacity="0.5"
                                                                                   x="13"
                                                                                   y="6"
                                                                                   width="13"
                                                                                   height="2"
                                                                                   rx="1"
                                                                                   transform="rotate(90 13 6)"
                                                                                   fill="currentColor"
                                                                               />
                                                                               <path
                                                                                   d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                                                   fill="currentColor"
                                                                               />
                                                                           </svg>
                                                                       </span>

                                                                       <div
                                                                           className="fs-2 fw-bold"
                                                                           data-kt-countup="true"
                                                                           data-kt-countup-value={useractivedata.totalCustomer}

                                                                       >
                                                                           {useractivedata.totalCustomer}
                                                                       </div>
                                                                   </div>

                                                                   <div className="fw-semibold fs-6 text-gray-400">
                                                                       Total Users
                                                                   </div>
                                                               </div>

                                                               {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                   <div className="d-flex align-items-center">
                                                                       <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                                           <svg
                                                                               width="24"
                                                                               height="24"
                                                                               viewBox="0 0 24 24"
                                                                               fill="none"
                                                                               xmlns="http://www.w3.org/2000/svg"
                                                                           >
                                                                               <rect
                                                                                   opacity="0.5"
                                                                                   x="11"
                                                                                   y="18"
                                                                                   width="13"
                                                                                   height="2"
                                                                                   rx="1"
                                                                                   transform="rotate(-90 11 18)"
                                                                                   fill="currentColor"
                                                                               />
                                                                               <path
                                                                                   d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                                                                   fill="currentColor"
                                                                               />
                                                                           </svg>
                                                                       </span>

                                                                       <div
                                                                           className="fs-2 fw-bold"
                                                                           data-kt-countup="true"
                                                                           data-kt-countup-value={
                                                                               section.totalTransection
                                                                           }
                                                                       >
                                                                           {section.totalTransection}
                                                                       </div>
                                                                   </div>

                                                                   <div className="fw-semibold fs-6 text-gray-400">
                                                                       Total Transactions{" "}
                                                                   </div>
                                                               </div> */}
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>

                                   </div>
                               </div>
                               <div className="col-lg-6">
                                   <div
                                       className="card rounded-15 mb-5 mb-xl-10  bgi-no-repeat bgi-position-x-end bgi-size-cover"
                                       style={{ paddingBottom: "1rem" }}
                                   >
                                       <div className="card-body  pt-9 pb-5 ">
                                           <div className="row">
                                               <div className="col-md-6">
                                                   <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                                                       <div className="symbol symbol-30px me-5 ">
                                                           <span className="symbol-label">
                                                               <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                   <i className="fad fa-users"></i>
                                                               </span>
                                                           </span>
                                                       </div>

                                                       {Last_Month <= 0 ? (
                                                           <i className="fal fa-arrow-up fz-20"></i>
                                                       ) : (
                                                           <i className="fal fa-arrow-down fz-21"></i>
                                                       )}
                                                       <div className=" ">
                                                           {/* {Last_Month <= 0 ? ( */}
                                                               <span
                                                                   data-kt-countup="true"
                                                                   data-kt-countup-value={
                                                                       useractivedata.thisMonth
                                                                   }
                                                                   className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  "
                                                               >
                                                                   {useractivedata.thisMonth}
                                                               </span>
                                                           {/* ) : (
                                                               <span
                                                                   data-kt-countup="true"
                                                                   data-kt-countup-value={
                                                                       useractivedata.Last_Month
                                                                   }
                                                                   className="text-gray-700 fz-24 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  "
                                                               >
                                                                   {useractivedata.Last_Month}
                                                               </span>
                                                           )} */}

                                                           <h6 className="nz-2">
                                                               <span className="text-gray-500 fw-semibold fs-6  ">
                                                                   New Users Registered
                                                               </span>
                                                           </h6>
                                                       </div>
                                                   </div>
                                               </div>



                                               <div className="col-md-6">
                                                   <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                                                       <div className="symbol symbol-30px me-5 ">
                                                           <span className="symbol-label">
                                                               <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                   <i className="fad fa-users"></i>
                                                               </span>
                                                           </span>
                                                       </div>

                                                       <i class="fas fa-bullseye-arrow fz-200"></i>

                                                       <div className=" ">
                                                           <span
                                                               className="text-gray-700 fz-230 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  "
                                                           >
                                                               {useractivedata.onBoarding}
                                                           </span>
                                                           <h6 className="nz-2">
                                                               <span className="text-gray-500 fw-semibold fs-6  ">
                                                                   Onboarding target for Next month
                                                               </span>
                                                           </h6>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>

               <div className="container-xxl" id="kt_content_container">
                   <div className="row g-5 g-xl-8">
                       <div className="col-lg-12">
                           <div className="row">
                               <div className="col-lg-12">
                                   <div className="card card-flush h-xl-100  mb-5">
                                       <div className="card-header pt-7">

                                           <h3 className="card-title align-items-start flex-column">
                                               <span className="card-label fw-bold text-gray-800">Super Agent  Activity</span>
                                               <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Super Agent </span>
                                           </h3>

                                           <div className="card-toolbar">
                                               <div className="row mb-6">


                                                   <div className="col-lg-12 fv-row">


                                                       <form >
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   {/* <select
                                                                       className="form-select border-0 flex"
                                                                       style={{ width: "10vw" }}

                                                                       onChange={datafilter}
                                                                       name="filter"
                                                                   >
                                                                       <option value="1" selected="selected">
                                                                           {" "}
                                                                           Data filter
                                                                       </option>
                                                                       <option value="Day"> Day</option>
                                                                       <option value="Month"> Months</option>
                                                                       <option value="Year"> Year</option>
                                                                   </select> */}
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       {/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
                                                                       <select defaultValue={frequency} onChange={handlesorting} className="form-select graph-select" >
                                                                           <option value="day"> Day</option>
                                                                           <option value="week"> Week</option>
                                                                           <option value="month"> Monthly</option>
                                                                           <option value="year">Yearly</option>

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                       </form>
                                                   </div>
                                               </div>


                                           </div>

                                       </div>


                                       <div className="bg-white overflow-auto">
                                           <Chart
                                               type="bar"
                                               width={'100%'}
                                               height={280}
                                               series={[
                                                   {
                                                       name: "User",
                                                       data: useractivedatayear,
                                                   },
                                               ]}
                                               options={{
                                                   plotOptions: {
                                                       bar: {
                                                           borderRadius: 2,
                                                           columnWidth: 50,
                                                       },
                                                   },
                                                   title: {
                                                       //   text: "BarChar Developed by DevOps Team",
                                                       //   style: { fontSize: 30 },
                                                   },

                                                   subtitle: {
                                                       //   text: "This is BarChart Graph",
                                                       //   style: { fontSize: 18 },
                                                   },

                                                   colors: ["#3c7f8c"],
                                                   theme: { mode: "light" },

                                                   xaxis: {
                                                       tickPlacement: "on",
                                                       categories: useractivedatamonths,
                                                       //   title: {
                                                       //     text: "Year BY data",
                                                       //     style: { color: "##3c7f8c", fontSize: 10 },
                                                       //   },
                                                   },

                                                   yaxis: {
                                                       labels: {
                                                           formatter: (val) => {
                                                               return `${val}`;
                                                           },
                                                           style: { fontSize: "15", colors: ["#3c7f8c"] },
                                                       },
                                                       title: {
                                                           // text: "User In (K)",
                                                           // style: { color: "#f90000", fontSize: 15 },
                                                       },
                                                   },

                                                   legend: {
                                                       show: true,
                                                       position: "right",
                                                   },

                                                   dataLabels: {
                                                       formatter: (val) => {
                                                           return `${val}`;
                                                       },
                                                       style: {
                                                           colors: ["white"],
                                                           fontSize: 12,
                                                           position: "top",
                                                       },
                                                   },
                                               }}
                                           ></Chart>
                                       </div>

                                   </div>
                               </div>
                               {/* <div className="col-lg-6">
                 <div className="card card-flush h-xl-100">
                 <div className="card-header pt-7">
                                          
                                          <h3 className="card-title align-items-start flex-column">
                                              <span className="card-label fw-bold text-gray-800">Organisation Transaction Activity</span>
                                              <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Organisation </span>
                                          </h3>
                                         
                                          <div className="card-toolbar">
                                          <div className="row mb-6">
                      

                      <div className="col-lg-12 fv-row">
                    

                        <select
                          className="form-select border-0 flex-grow-1"
                          required
                          data-control="select2"
                          data-placeholder="assetType"
                          data-hide-search="true"
                          name="assetType"
                        >
                          <option value="1" selected="selected">
                            {" "}
                            Data filler
                          </option>
                          <option value="week"> Week</option>
                          <option value="months"> Months</option>
                          <option value="year"> Year</option>
                        
                
                        </select>
                      </div>
                    </div>
                                           
                                            
                                          </div>
                                         
                                      </div>

                 
                     <div className="bg-white overflow-auto">
                       <Chart
                         type="area"
                         width={'100%'}
                         height={300}
                         series={[
                           {
                               name: "Transaction",
                             data: [6578, 6787, 3245, 9876, 2324, 5123, 2435,5698,2563,5896,458,6259],
                           },
                         ]}
                         options={{
                           stroke: {
                             width: 2,
                           },

                           colors: ["#3c7f8c"],
                           theme: { mode: "light" },

                           xaxis: {
                             tickPlacement: "on",
                             categories: [
                               "December",
                               "January",
                               "February",
                               "March",
                               "April",
                               "May",
                               "June",
                               "July",
                               "August",
                               "September",
                               "October",
                               "November",
                             ],
                             title: {
                               style: { color: "#f90000", fontSize: 0 },
                             },
                           },

                           yaxis: {
                             labels: {
                               formatter: (val) => {
                                 return `${val}`;
                               },
                             },
                             //
                           },

                           legend: {
                             show: true,
                             position: "right",
                           },

                           dataLabels: {
                             formatter: (val) => {
                               return `${val}`;
                             },
                             style: {
                               colors: ["#3c7f8c"],
                               fontSize: 15,
                             },
                           },
                         }}
                       ></Chart>
              
                   </div>
                 </div>
               </div> */}
                           </div>
                       </div>
                       <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

                           <div className="container-xxl d-flex flex-column flex-md-row flex-stack">

                               {/* <div className="text-dark order-2 order-md-1">
                                   <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                                   <a href="#" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
                               </div> */}


                               {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">About</a>
                                   </li>
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">Support</a>
                                   </li>

                               </ul> */}

                           </div>

                       </div>
                   </div>
               </div>


               {/* saving managment */}

               <div className="container-xxl" id="kt_content_container">
                   <div className="row g-5 g-xl-8">
                       <div className="col-lg-12">
                           <div className="row">
                               <div className="col-lg-12">
                                   <div className="card card-flush h-xl-100  mb-5">
                                       <div className="card-header pt-7">

                                           <h3 className="card-title align-items-start flex-column">
                                               <span className="card-label fw-bold text-gray-800">Super Agent Saving Transacctions</span>
                                               <span className="text-gray-400 mt-1 fw-semibold fs-6">Saving Transactions Of Super Agent </span>
                                           </h3>

                                           <div className="card-toolbar">
                                               <div className="row mb-6">


                                                   <div className="col-lg-12 fv-row">


                                                       <form >
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   {/* <select
                                                                       className="form-select border-0 flex"
                                                                       style={{ width: "10vw" }}

                                                                       onChange={datafilter}
                                                                       name="filter"
                                                                   >
                                                                       <option value="1" selected="selected">
                                                                           {" "}
                                                                           Data filter
                                                                       </option>
                                                                       <option value="Day"> Day</option>
                                                                       <option value="Month"> Months</option>
                                                                       <option value="Year"> Year</option>
                                                                   </select> */}
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       {/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
                                                                       <select defaultValue={frequency} onChange={handleSavingsorting} className="form-select graph-select" >
                                                                           <option value="day"> Day</option>
                                                                           <option value="week"> Week</option>
                                                                           <option value="month"> Monthly</option>
                                                                           <option value="year">Yearly</option>

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   {/* <select
                                                                       className="form-select border-0 flex"
                                                                       style={{ width: "10vw" }}

                                                                       onChange={datafilter}
                                                                       name="filter"
                                                                   >
                                                                       <option value="1" selected="selected">
                                                                           {" "}
                                                                           Data filter
                                                                       </option>
                                                                       <option value="Day"> Day</option>
                                                                       <option value="Month"> Months</option>
                                                                       <option value="Year"> Year</option>
                                                                   </select> */}
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       {/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
                                                                       <select defaultValue={savingTypeFrequency} onChange={handleSavingTypesorting} className="form-select graph-select" >
                                                                           <option value="mandatory"> Mandatory</option>
                                                                           <option value="voluantary"> Voluntary</option>
                                                                         

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                       </form>
                                                   </div>
                                               </div>


                                           </div>

                                       </div>


                                       <div className="bg-white overflow-auto">
                                           <Chart
                                               type="bar"
                                               width={'100%'}
                                               height={280}
                                               series={[
                                                   {
                                                       name: "User",
                                                       data: useractiveSavingdatayear,
                                                   },
                                               ]}
                                               options={{
                                                   plotOptions: {
                                                       bar: {
                                                           borderRadius: 2,
                                                           columnWidth: 50,
                                                       },
                                                   },
                                                   title: {
                                                       //   text: "BarChar Developed by DevOps Team",
                                                       //   style: { fontSize: 30 },
                                                   },

                                                   subtitle: {
                                                       //   text: "This is BarChart Graph",
                                                       //   style: { fontSize: 18 },
                                                   },

                                                   colors: ["#3c7f8c"],
                                                   theme: { mode: "light" },

                                                   xaxis: {
                                                       tickPlacement: "on",
                                                       categories: useractiveSavingdatamonths,
                                                       //   title: {
                                                       //     text: "Year BY data",
                                                       //     style: { color: "##3c7f8c", fontSize: 10 },
                                                       //   },
                                                   },

                                                   yaxis: {
                                                       labels: {
                                                           formatter: (val) => {
                                                               return `${val}`;
                                                           },
                                                           style: { fontSize: "15", colors: ["#3c7f8c"] },
                                                       },
                                                       title: {
                                                           // text: "User In (K)",
                                                           // style: { color: "#f90000", fontSize: 15 },
                                                       },
                                                   },

                                                   legend: {
                                                       show: true,
                                                       position: "right",
                                                   },

                                                   dataLabels: {
                                                       formatter: (val) => {
                                                           return `${val}`;
                                                       },
                                                       style: {
                                                           colors: ["white"],
                                                           fontSize: 12,
                                                           position: "top",
                                                       },
                                                   },
                                               }}
                                           ></Chart>
                                       </div>

                                   </div>
                               </div>
                               {/* <div className="col-lg-6">
                 <div className="card card-flush h-xl-100">
                 <div className="card-header pt-7">
                                          
                                          <h3 className="card-title align-items-start flex-column">
                                              <span className="card-label fw-bold text-gray-800">Organisation Transaction Activity</span>
                                              <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Organisation </span>
                                          </h3>
                                         
                                          <div className="card-toolbar">
                                          <div className="row mb-6">
                      

                      <div className="col-lg-12 fv-row">
                    

                        <select
                          className="form-select border-0 flex-grow-1"
                          required
                          data-control="select2"
                          data-placeholder="assetType"
                          data-hide-search="true"
                          name="assetType"
                        >
                          <option value="1" selected="selected">
                            {" "}
                            Data filler
                          </option>
                          <option value="week"> Week</option>
                          <option value="months"> Months</option>
                          <option value="year"> Year</option>
                        
                
                        </select>
                      </div>
                    </div>
                                           
                                            
                                          </div>
                                         
                                      </div>

                 
                     <div className="bg-white overflow-auto">
                       <Chart
                         type="area"
                         width={'100%'}
                         height={300}
                         series={[
                           {
                               name: "Transaction",
                             data: [6578, 6787, 3245, 9876, 2324, 5123, 2435,5698,2563,5896,458,6259],
                           },
                         ]}
                         options={{
                           stroke: {
                             width: 2,
                           },

                           colors: ["#3c7f8c"],
                           theme: { mode: "light" },

                           xaxis: {
                             tickPlacement: "on",
                             categories: [
                               "December",
                               "January",
                               "February",
                               "March",
                               "April",
                               "May",
                               "June",
                               "July",
                               "August",
                               "September",
                               "October",
                               "November",
                             ],
                             title: {
                               style: { color: "#f90000", fontSize: 0 },
                             },
                           },

                           yaxis: {
                             labels: {
                               formatter: (val) => {
                                 return `${val}`;
                               },
                             },
                             //
                           },

                           legend: {
                             show: true,
                             position: "right",
                           },

                           dataLabels: {
                             formatter: (val) => {
                               return `${val}`;
                             },
                             style: {
                               colors: ["#3c7f8c"],
                               fontSize: 15,
                             },
                           },
                         }}
                       ></Chart>
              
                   </div>
                 </div>
               </div> */}
                           </div>
                       </div>
                       <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

                           <div className="container-xxl d-flex flex-column flex-md-row flex-stack">

                               {/* <div className="text-dark order-2 order-md-1">
                                   <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                                   <a href="#" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
                               </div>


                               <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">About</a>
                                   </li>
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">Support</a>
                                   </li>

                               </ul> */}

                           </div>

                       </div>
                   </div>
               </div>



               <div className="container-xxl" id="kt_content_container" >
                   <div className="row g-5 g-xl-8" >
                       <div className="col-lg-12" >
                           <div className="row" >
                               <div className="col-lg-12">
                                   <div className="card card-flush h-xl-100  mb-5">
                                       <div className="card-header pt-7">

                                           <h3 className="card-title align-items-start flex-column">
                                               <span className="card-label fw-bold text-gray-800">Super Agent Store Transacctions</span>
                                               <span className="text-gray-400 mt-1 fw-semibold fs-6">Store Transactions Of Super Agent </span>
                                           </h3>

                                           <div className="card-toolbar">
                                               <div className="row mb-6">


                                                   <div className="col-lg-12 fv-row">


                                                       <form >
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   {/* <select
                                                                       className="form-select border-0 flex"
                                                                       style={{ width: "10vw" }}

                                                                       onChange={datafilter}
                                                                       name="filter"
                                                                   >
                                                                       <option value="1" selected="selected">
                                                                           {" "}
                                                                           Data filter
                                                                       </option>
                                                                       <option value="Day"> Day</option>
                                                                       <option value="Month"> Months</option>
                                                                       <option value="Year"> Year</option>
                                                                   </select> */}
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       {/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
                                                                       <select defaultValue={storeFrequency} onChange={handleStoreTypesorting} className="form-select graph-select" >
                                                                           <option value="day"> Day</option>
                                                                           <option value="week"> Week</option>
                                                                           <option value="month"> Monthly</option>
                                                                           <option value="year">Yearly</option>

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                           
                                                       </form>
                                                   </div>
                                               </div>


                                           </div>

                                       </div>


                                       <div className="bg-white overflow-auto">
                                           <Chart
                                               type="bar"
                                               width={'100%'}
                                               height={280}
                                               series={[
                                                   {
                                                       name: "User",
                                                       data: useractiveStoredatayear,
                                                   },
                                               ]}
                                               options={{
                                                   plotOptions: {
                                                       bar: {
                                                           borderRadius: 2,
                                                           columnWidth: 50,
                                                       },
                                                   },
                                                   title: {
                                                       //   text: "BarChar Developed by DevOps Team",
                                                       //   style: { fontSize: 30 },
                                                   },

                                                   subtitle: {
                                                       //   text: "This is BarChart Graph",
                                                       //   style: { fontSize: 18 },
                                                   },

                                                   colors: ["#3c7f8c"],
                                                   theme: { mode: "light" },

                                                   xaxis: {
                                                       tickPlacement: "on",
                                                       categories: useractiveStoredatamonths,
                                                       //   title: {
                                                       //     text: "Year BY data",
                                                       //     style: { color: "##3c7f8c", fontSize: 10 },
                                                       //   },
                                                   },

                                                   yaxis: {
                                                       labels: {
                                                           formatter: (val) => {
                                                               return `${val}`;
                                                           },
                                                           style: { fontSize: "15", colors: ["#3c7f8c"] },
                                                       },
                                                       title: {
                                                           // text: "User In (K)",
                                                           // style: { color: "#f90000", fontSize: 15 },
                                                       },
                                                   },

                                                   legend: {
                                                       show: true,
                                                       position: "right",
                                                   },

                                                   dataLabels: {
                                                       formatter: (val) => {
                                                           return `${val}`;
                                                       },
                                                       style: {
                                                           colors: ["white"],
                                                           fontSize: 12,
                                                           position: "top",
                                                       },
                                                   },
                                               }}
                                           ></Chart>
                                       </div>

                                   </div>
                               </div>
                               {/* <div className="col-lg-6">
                 <div className="card card-flush h-xl-100">
                 <div className="card-header pt-7">
                                          
                                          <h3 className="card-title align-items-start flex-column">
                                              <span className="card-label fw-bold text-gray-800">Organisation Transaction Activity</span>
                                              <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Organisation </span>
                                          </h3>
                                         
                                          <div className="card-toolbar">
                                          <div className="row mb-6">
                      

                      <div className="col-lg-12 fv-row">
                    

                        <select
                          className="form-select border-0 flex-grow-1"
                          required
                          data-control="select2"
                          data-placeholder="assetType"
                          data-hide-search="true"
                          name="assetType"
                        >
                          <option value="1" selected="selected">
                            {" "}
                            Data filler
                          </option>
                          <option value="week"> Week</option>
                          <option value="months"> Months</option>
                          <option value="year"> Year</option>
                        
                
                        </select>
                      </div>
                    </div>
                                           
                                            
                                          </div>
                                         
                                      </div>

                 
                     <div className="bg-white overflow-auto">
                       <Chart
                         type="area"
                         width={'100%'}
                         height={300}
                         series={[
                           {
                               name: "Transaction",
                             data: [6578, 6787, 3245, 9876, 2324, 5123, 2435,5698,2563,5896,458,6259],
                           },
                         ]}
                         options={{
                           stroke: {
                             width: 2,
                           },

                           colors: ["#3c7f8c"],
                           theme: { mode: "light" },

                           xaxis: {
                             tickPlacement: "on",
                             categories: [
                               "December",
                               "January",
                               "February",
                               "March",
                               "April",
                               "May",
                               "June",
                               "July",
                               "August",
                               "September",
                               "October",
                               "November",
                             ],
                             title: {
                               style: { color: "#f90000", fontSize: 0 },
                             },
                           },

                           yaxis: {
                             labels: {
                               formatter: (val) => {
                                 return `${val}`;
                               },
                             },
                             //
                           },

                           legend: {
                             show: true,
                             position: "right",
                           },

                           dataLabels: {
                             formatter: (val) => {
                               return `${val}`;
                             },
                             style: {
                               colors: ["#3c7f8c"],
                               fontSize: 15,
                             },
                           },
                         }}
                       ></Chart>
              
                   </div>
                 </div>
               </div> */}
                           </div>
                       </div>
                       <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

                           <div className="container-xxl d-flex flex-column flex-md-row flex-stack">

                               <div className="text-dark order-2 order-md-1">
                                   <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                                   <a href="#" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
                               </div>


                               <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">About</a>
                                   </li>
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">Support</a>
                                   </li>

                               </ul>

                           </div>

                       </div>
                   </div>
               </div>

           </div>
       </>
   );
}
  

export default TransactionSuperReports