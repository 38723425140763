import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ReactApexChart from "react-apexcharts";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import Sidebar from "../Sidebar";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CustomerLoader from "../CRM/Modal/CustomerLoder";
import ModalPasswordVerify from "./ModalPasswordVerify/ModalPasswordVerify";
var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function ShareManagementDashboard({ hadlersuper }) {
  const [data, setData] = useState([]);

  const [userPro, setUserinfo] = useState([]);
  const [userLoanDetail, setUserLoan] = useState([]);

  const [shareBuySell, setShareBuySell] = useState([]);

  const [pageCount, setpageCount] = useState("");

  const [Delethandler, setDelethandler] = useState(false);
  const [verifySharepass, setVerifyShare] = useState(false);
  const [depositeBank, setDeposite] = useState("");

  const [Quantity, setQuantity] = useState(0);
  const [SharePrice, setSharePrice] = useState("");
  const [latestSharePrice, setCurrSharePrice] = useState("");
  const [investAmount, setInvestAmount] = useState(0);
  const [customer, setCutsmerImage] = useState("");
  const [fullinfosharewithdraw, setfullinfosharewithdraw] = useState([]);
  const [activeTab, setactiveTab] = useState("addshare");

  const [DateObject, setDateObject] = useState([]);
  const [PriceValue, setPriceValue] = useState([]);
  const [transId, settransId] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [ShowLoader, setShowLoader] = useState(false);
  const [ShowModel, setShowModel] = useState(false);
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let { _id } = useParams();

  const handleCalculate = (e) => {
    const { name, value } = e.target;

    console.log({name , value})
  
    if (value < 0 || !/^\d*\.?\d*$/.test(value)) return;
  
    if (name === "quantity") {
      setQuantity(value);
       console.log("investment quantity",{value})
      // Calculate investAmount based on quantity and latestSharePrice
      setInvestAmount(
        value ? (parseFloat(value) * parseFloat(latestSharePrice)) : ""
      );
    } else if (name === "investAmount") {
      setInvestAmount(value);
  
      // Calculate quantity based on investAmount and latestSharePrice
      setQuantity(
        value
          ? (parseFloat(value) / parseFloat(latestSharePrice))
          : ""
      );
    }
  };
  

  function formatLargeNumber(Amount, decimalPlaces) {
    // Check if amount is a number

    let amount = parseInt(Amount);

    if (typeof amount !== "number") {
      return "Invalid input";
    }

    // Separate integer and decimal parts
    const [integerPart, decimalPart] = amount?.toFixed(decimalPlaces).split(".");

    // Add commas to the integer part
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    // Concatenate integer and decimal parts
    let formattedAmount = formattedIntegerPart;
    if (decimalPart !== undefined && decimalPart !== "") {
      formattedAmount += "." + decimalPart;
    }

    return formattedAmount;
  }

  const custumerDetails = () => {
    axios
      .post(
        `/v1/super-Agent/cust-detail `,
        { custID: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.Data;

        setUserinfo(data);
      });
  };
  const currenrSharePrice = () => {
    axios
      .post(
        `/v1/super-Agent/get-super-share-price`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        console.log("share=price", data);

        setCurrSharePrice(data);
      });
  };

  const handleFileChange = (data) => {
    let formdata = new FormData();
    // console.log("@#@#@123" + data);

    if (data) {
      const url = URL.createObjectURL(data);
      console.log("@#@#@" + url);
      setImageUrl(url);
      formdata.append("file", data);

      console.log("image first data", formdata);

      formdata.append("Image", data);

      console.log("image first data", formdata);
      axios
        .post(`/v1/DID/ImageUploader`, formdata, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const imgurl = res.data.data;
          console.log("image url", imgurl);
          setCutsmerImage(imgurl);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    } else {
      toast.error(data.msg);
    }
    // })
    // .catch(error => {
    //   console.error("Error checking face API:", error);
    // });
    // } else {

    // }
  };

  const custumerLoanDetail = () => {
    axios
      .post(
        `/v1/DID/get-both-saving-amount`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data;

        setUserLoan(saveData);
      });
  };

  // Add share  Pagination done code
  const limit = 5;

  const AddShareData = async (e) => {
    // e.preventDefault();
    // var data = new FormData(e.target);

    var Formvlaues = {};
    Formvlaues.custId = _id;
    Formvlaues.investAmount = parseInt(Quantity) * parseFloat(latestSharePrice);
    Formvlaues.sharePrice = latestSharePrice;
    Formvlaues.quantity = Quantity;
    Formvlaues.payType = depositeBank;
    Formvlaues.depositSlip = customer ? customer : "";

    axios
      .post(`/v1/super-Agent/add-shares`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            setTimeout(() => {
              AddShareDeatailshistory();
            }, 2000);
            ShareCalculation();
            toast.success(data.msg);
            custumerLoanDetail();
            setSharePrice("");
            setQuantity("");
            setInvestAmount(0);
            e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const AddShareDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/view-shares`,
        { custId: _id, page: 1, type: "deposite", savingType: "" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setShareBuySell(saveData);
        const total = resp.data.totalRow;
        const totalPage = Math.ceil(total / limit);
        console.log("=-=-=->", { totalPage });
        setpageCount(totalPage || 0);

        console.log("UserShare", total);
      });
  };

  const handlePageClickpage = async (data) => {
    const page = data.selected + 1;
    setShowLoader(true);
    try {
      const resp = await axios.post(
        `/v1/super-Agent/view-shares`,
        { custId: _id, page: page },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let saveData = resp.data.data;
      setShareBuySell(saveData);
      console.log("Usersaving", saveData);

      // Now you can use saveData wherever you need it
      // ...
      setShowLoader(false);
      console.log("Usersaving", resp.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [filterdata, setfilterdata] = useState("all");

  const fitteruserdata = (e) => {
    setShowLoader(true);
    axios
      .post(
        `/v1/super-Agent/view-shares`,
        { custId: _id, page: 1, savingType: filterdata },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setShareBuySell(saveData);
        setShowLoader(false);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage || 0);
      })
      .catch(error);
  };

  useEffect(() => {
    console.log({ filterdata });
    fitteruserdata();
    currenrSharePrice();
  }, [filterdata]);

  //  add share Pagination done end

  // share  withdraw Pagination done code  start

  const ShareCalculation = () => {
    axios
      .post(
        `v1/super-Agent/share-calculation`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data;
        console.log("Share", { data });
        setfullinfosharewithdraw(data?.data);
      });
  };

  const shareWithdraw = () => {
    axios
      .post(
        `v1/super-Agent/withdrawal-shares`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data;
        toast.success(data.msg);
      });
  };

  useEffect(() => {
    ShareCalculation();
    SharePriceGraph();
  }, []);

  // share  withdraw Pagination done code  end

  // Share graph

  const SharePriceGraph = (e) => {
    axios
      .post(
        `v1/org/CRM/all-update-share-price`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;

          if (data.status) {
            let apiData = data.data;
            let price = apiData?.map((item) => item?.newPrice);
            let Datetoshare = apiData.map((item) =>
              moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
            );

            console.log({ apiData, price });

            setDateObject(Datetoshare);
            setPriceValue(price);
            toast.success(data.msg);
            return;
          } else {
            toast.error(data.msg);
          }
        }
      });
  };

  const SharePriceFilterGraph = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    axios
      .post(`v1/org/CRM/all-update-share-price`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;

          if (data.status) {
            let apiData = data.data;
            let price = apiData?.map((item) => item?.newPrice);
            let Datetoshare = apiData.map((item) =>
              moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
            );

            console.log({ apiData, price });

            setDateObject(Datetoshare);
            setPriceValue(price);
            toast.success(data.msg);
            return;
          } else {
            toast.error(data.msg);
          }
        }
      });
  };

  // api call
  useEffect(() => {
    hadlersuper();
    AddShareDeatailshistory();
    custumerDetails();
    custumerLoanDetail();
  }, []);

  const DeleteVeriflyAfterSubmit = (e) => {
    e.preventDefault();
    setDelethandler(true);
  };

  const DeleteVeriflPassyAfterSubmit = (e) => {
    e.preventDefault();
    setVerifyShare(true);
  };

  const VerfiyDelete = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // setVerifyShare(false)
      let data = response.data;

      if (data.status) {
        setShowLoader(false);
        setDelethandler(false);
        if (activeTab == "sharewithdrawal") {
          Swal.fire({
            title: "Are you sure?",
            text: "Are you certain you want to withdraw  Share the entire balance? Please note that upon withdrawal, your Share account will be closed permanently.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm it!",
          }).then((result) => {
            if (result.isConfirmed) {
              shareWithdraw();
            }
          });
        }
      } else {
        toast.error(response.data.message);
        setDelethandler(true);
        setShowLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };

  const VerfiyAddSharePassDelete = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // setVerifyShare(false)
      let data = response.data;

      if (data.status) {
        setShowLoader(false);

        setVerifyShare(false);
        if (activeTab == "addshare") {
          AddShareData(e);
          e.target.reset();
        }
      } else {
        toast.error(response.data.message);
        setShowLoader(false);
        setVerifyShare(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };

  const [chartData, setChartData] = useState({
    series: [
      {
        data: [
          {
            x: new Date(1538778600000),
            y: [6629.81, 6650.5, 6623.04, 6633.33],
          },
          {
            x: new Date(1538780400000),
            y: [6632.01, 6643.59, 6620, 6630.11],
          },
          {
            x: new Date(1538782200000),
            y: [6630.71, 6648.95, 6623.34, 6635.65],
          },
          {
            x: new Date(1538784000000),
            y: [6635.65, 6651, 6629.67, 6638.24],
          },
          {
            x: new Date(1538785800000),
            y: [6638.24, 6640, 6620, 6624.47],
          },
          {
            x: new Date(1538787600000),
            y: [6624.53, 6636.03, 6621.68, 6624.31],
          },
          {
            x: new Date(1538789400000),
            y: [6624.61, 6632.2, 6617, 6626.02],
          },
          {
            x: new Date(1538791200000),
            y: [6627, 6627.62, 6584.22, 6603.02],
          },
          {
            x: new Date(1538793000000),
            y: [6605, 6608.03, 6598.95, 6604.01],
          },
          {
            x: new Date(1538794800000),
            y: [6604.5, 6614.4, 6602.26, 6608.02],
          },
          {
            x: new Date(1538796600000),
            y: [6608.02, 6610.68, 6601.99, 6608.91],
          },
          {
            x: new Date(1538798400000),
            y: [6608.91, 6618.99, 6608.01, 6612],
          },
          {
            x: new Date(1538800200000),
            y: [6612, 6615.13, 6605.09, 6612],
          },
          {
            x: new Date(1538802000000),
            y: [6612, 6624.12, 6608.43, 6622.95],
          },
          {
            x: new Date(1538803800000),
            y: [6623.91, 6623.91, 6615, 6615.67],
          },
          {
            x: new Date(1538805600000),
            y: [6618.69, 6618.74, 6610, 6610.4],
          },
          {
            x: new Date(1538807400000),
            y: [6611, 6622.78, 6610.4, 6614.9],
          },
          {
            x: new Date(1538809200000),
            y: [6614.9, 6626.2, 6613.33, 6623.45],
          },
          {
            x: new Date(1538811000000),
            y: [6623.48, 6627, 6618.38, 6620.35],
          },
          {
            x: new Date(1538812800000),
            y: [6619.43, 6620.35, 6610.05, 6615.53],
          },
          {
            x: new Date(1538814600000),
            y: [6615.53, 6617.93, 6610, 6615.19],
          },
          {
            x: new Date(1538816400000),
            y: [6615.19, 6621.6, 6608.2, 6620],
          },
          {
            x: new Date(1538818200000),
            y: [6619.54, 6625.17, 6614.15, 6620],
          },
          {
            x: new Date(1538820000000),
            y: [6620.33, 6634.15, 6617.24, 6624.61],
          },
          {
            x: new Date(1538821800000),
            y: [6625.95, 6626, 6611.66, 6617.58],
          },
          {
            x: new Date(1538823600000),
            y: [6619, 6625.97, 6595.27, 6598.86],
          },
          {
            x: new Date(1538825400000),
            y: [6598.86, 6598.88, 6570, 6587.16],
          },
          {
            x: new Date(1538827200000),
            y: [6588.86, 6600, 6580, 6593.4],
          },
          {
            x: new Date(1538829000000),
            y: [6593.99, 6598.89, 6585, 6587.81],
          },
          {
            x: new Date(1538830800000),
            y: [6587.81, 6592.73, 6567.14, 6578],
          },
          {
            x: new Date(1538832600000),
            y: [6578.35, 6581.72, 6567.39, 6579],
          },
          {
            x: new Date(1538834400000),
            y: [6579.38, 6580.92, 6566.77, 6575.96],
          },
          {
            x: new Date(1538836200000),
            y: [6575.96, 6589, 6571.77, 6588.92],
          },
          {
            x: new Date(1538838000000),
            y: [6588.92, 6594, 6577.55, 6589.22],
          },
          {
            x: new Date(1538839800000),
            y: [6589.3, 6598.89, 6589.1, 6596.08],
          },
          {
            x: new Date(1538841600000),
            y: [6597.5, 6600, 6588.39, 6596.25],
          },
          {
            x: new Date(1538843400000),
            y: [6598.03, 6600, 6588.73, 6595.97],
          },
          {
            x: new Date(1538845200000),
            y: [6595.97, 6602.01, 6588.17, 6602],
          },
          {
            x: new Date(1538847000000),
            y: [6602, 6607, 6596.51, 6599.95],
          },
          {
            x: new Date(1538848800000),
            y: [6600.63, 6601.21, 6590.39, 6591.02],
          },
          {
            x: new Date(1538850600000),
            y: [6591.02, 6603.08, 6591, 6591],
          },
          {
            x: new Date(1538852400000),
            y: [6591, 6601.32, 6585, 6592],
          },
          {
            x: new Date(1538854200000),
            y: [6593.13, 6596.01, 6590, 6593.34],
          },
          {
            x: new Date(1538856000000),
            y: [6593.34, 6604.76, 6582.63, 6593.86],
          },
          {
            x: new Date(1538857800000),
            y: [6593.86, 6604.28, 6586.57, 6600.01],
          },
          {
            x: new Date(1538859600000),
            y: [6601.81, 6603.21, 6592.78, 6596.25],
          },
          {
            x: new Date(1538861400000),
            y: [6596.25, 6604.2, 6590, 6602.99],
          },
          {
            x: new Date(1538863200000),
            y: [6602.99, 6606, 6584.99, 6587.81],
          },
          {
            x: new Date(1538865000000),
            y: [6587.81, 6595, 6583.27, 6591.96],
          },
          {
            x: new Date(1538866800000),
            y: [6591.97, 6596.07, 6585, 6588.39],
          },
          {
            x: new Date(1538868600000),
            y: [6587.6, 6598.21, 6587.6, 6594.27],
          },
          {
            x: new Date(1538870400000),
            y: [6596.44, 6601, 6590, 6596.55],
          },
          {
            x: new Date(1538872200000),
            y: [6598.91, 6605, 6596.61, 6600.02],
          },
          {
            x: new Date(1538874000000),
            y: [6600.55, 6605, 6589.14, 6593.01],
          },
          {
            x: new Date(1538875800000),
            y: [6593.15, 6605, 6592, 6603.06],
          },
          {
            x: new Date(1538877600000),
            y: [6603.07, 6604.5, 6599.09, 6603.89],
          },
          {
            x: new Date(1538879400000),
            y: [6604.44, 6604.44, 6600, 6603.5],
          },
          {
            x: new Date(1538881200000),
            y: [6603.5, 6603.99, 6597.5, 6603.86],
          },
          {
            x: new Date(1538883000000),
            y: [6603.85, 6605, 6600, 6604.07],
          },
          {
            x: new Date(1538884800000),
            y: [6604.98, 6606, 6604.07, 6606],
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "candlestick",
        height: 350,
      },
      title: {
        text: "CandleStick Chart",
        align: "left",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
    },
  });

  // invoice

  const handleDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Share  Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Share Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(
      invoiceData.investingDate
    ).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Share Details", 10, 120);

    // Add the share details table
    doc.autoTable({
      startY: 140,
      head: [
        [
          "Share ID",
          // "Saving ID",
          "Share Price (ETB)",
          // "Saving Price (ETB)",
          "Share Quantity",
          "Payment Method",
        ],
      ],
      body: [
        [
          invoiceData.shareId,
          // invoiceData.savingId,
          // `${invoiceData.sharePrice}`,
          `${invoiceData.sharePrice || 0}`,
          invoiceData.quantity,
          invoiceData.payType,
        ],
      ],
    });

    // Add the total share amount and total saving amount on the right side in a smaller font size
    const totalTextSize = 10;
    const totalX = width - 60; // Adjust as needed
    doc.setFontSize(totalTextSize);
    doc.text(
      `Total Share Amount (ETB): ${invoiceData.investAmount || 0}`,
      totalX,
      doc.autoTable.Previous.finalY + 10
    );
    // doc.text(
    //   `Total Saving Amount (ETB): $ ${invoiceData.investAmount}`,
    //   totalX,
    //   doc.autoTable.Previous.finalY + 20
    // );

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  const handleShareWithdrawalDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Share  Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Share Withdrawal Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(
      invoiceData.investingDate
    ).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Share Details", 10, 120);

    // Add the share details table
    doc.autoTable({
      startY: 140,
      head: [
        [
          "Share ID",
          // "Saving ID",
          "Share Price (ETB)",
          // "Saving Price (ETB)",
          "Share Quantity",
          "Payment Method",
        ],
      ],
      body: [
        [
          invoiceData.shareId,
          // invoiceData.savingId,
          // `${invoiceData.sharePrice}`,
          `${invoiceData.sharePrice || 0}`,
          invoiceData.quantity,
          invoiceData.payType,
        ],
      ],
    });

    // Add the total share amount and total saving amount on the right side in a smaller font size
    const totalTextSize = 10;
    const totalX = width - 60; // Adjust as needed
    doc.setFontSize(totalTextSize);
    doc.text(
      `Total Share Amount (ETB): ${invoiceData.investAmount || 0}`,
      totalX,
      doc.autoTable.Previous.finalY + 10
    );
    // doc.text(
    //   `Total Saving Amount (ETB): $ ${invoiceData.investAmount}`,
    //   totalX,
    //   doc.autoTable.Previous.finalY + 20
    // );

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  const handleCancelTransaction = async () => {
    console.log({ transId });
    try {
      const response = await axios.post(
        `/v1/super-Agent/cancel-share-trans`,
        { transactionId: transId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.status) {
        toast.success(response.data.msg);
        AddShareDeatailshistory();
        custumerDetails();
        custumerLoanDetail();
        ShareCalculation();
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {}
  };

  // const handleDownloadInvoice = (invoiceData) => {
  //   // Create a new jsPDF instance
  //   const doc = new jsPDF();
  //   console.log({ invoiceData });

  //   // Set up styling for the PDF
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(18);

  //   const width = doc.internal.pageSize.getWidth();

  //   // Calculate the width of the text for the invoice heading
  //   const headingTextWidth =
  //       (doc.getStringUnitWidth("Your Withdrawal Deposit Invoice") *
  //           doc.internal.getFontSize()) /
  //       doc.internal.scaleFactor;
  //   const headingX = (width - headingTextWidth) / 2;

  //   // Add the invoice heading
  //   doc.text("Your Withdrawal Deposit Invoice", headingX, 20);

  //   // Format the date as dd mm yyyy
  //   const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
  //       "en-GB", {
  //           day: "2-digit",
  //           month: "short",
  //           year: "numeric",
  //       }
  //   );

  //   // Add the date on the right side
  //   const dateTextWidth =
  //       (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
  //           doc.internal.getFontSize()) /
  //       doc.internal.scaleFactor;
  //   const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(15);
  //   doc.text(`Date: ${formattedDate}`, dateX, 40);

  //   // Set up styling for the section headings
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(20);

  //   // Add the customer details heading with left alignment
  //   doc.text("Customer Details", 10, 60);

  //   // Add the customer details
  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(15);
  //   doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
  //   doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
  //   doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

  //   // Add the saving details heading with left alignment
  //   doc.text("Saving Details", 10, 120);

  //   // Add the share details table
  //   if (invoiceData.payType == "mandatory") {
  //       doc.autoTable({
  //           startY: 140,
  //           head: [
  //               [
  //                   "Share ID",
  //                   "Saving ID",
  //                   "Share Price (ETB)",
  //                   "Saving Interest %",
  //                   "Share Quantity",
  //                   "Saving Type",
  //               ],
  //           ],
  //           body: [
  //               [
  //                   invoiceData.shareId,
  //                   invoiceData.savingId,
  //                   ` ${invoiceData.sharePrice}`,
  //                   `${invoiceData.interest || 0}`,
  //                   invoiceData.quantity,
  //                   invoiceData.savingType,
  //               ],
  //           ],
  //       });

  //       // Add the total share amount and total saving amount on the right side in a smaller font size
  //       const totalTextSize = 10;
  //       const totalX = width - 70; // Adjust as needed
  //       doc.setFontSize(totalTextSize);
  //       doc.text(
  //           `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
  //           totalX,
  //           doc.autoTable.Previous.finalY + 10
  //       );
  //       doc.text(
  //           `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
  //           totalX,
  //           doc.autoTable.Previous.finalY + 20
  //       );
  //   } else {
  //       doc.autoTable({
  //           startY: 140,
  //           head: [
  //               ["Saving ID", "Saving Interest %", "Saving Type"]
  //           ],
  //           body: [
  //               [
  //                   invoiceData.savingId,
  //                   `${invoiceData.interest || 0}`,
  //                   "Voluntary",
  //               ],
  //           ],
  //       });

  //       // Add the total saving amount on the right side in a smaller font size
  //       const totalTextSize = 10;
  //       const totalX = width - 70; // Adjust as needed
  //       doc.setFontSize(totalTextSize);
  //       doc.text(
  //           `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
  //           totalX,
  //           doc.autoTable.Previous.finalY + 20
  //       );
  //   }

  //   // Save the PDF and initiate download
  //   doc.save("invoice.pdf");
  // };
  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              {/* <!--begin: Pic--> */}
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={
                                      userPro.IDphoto ||
                                      "/assets_new/images/lodingwallet.gif"
                                    }
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              {/* <!--end::Pic--> */}

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  {/* <!--begin::User--> */}
                                  <div className="d-flex flex-column">
                                    {/* <!--begin::Name--> */}
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {userPro.fullname}
                                      </a>

                                      {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                    </div>
                                    {/* <!--end::Name--> */}

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.professoin}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.nationality || "Ethiopia"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.email}
                                      </a>
                                    </div>
                                    {/* <!--end::Info--> */}
                                  </div>
                                  {/* <!--end::User--> */}
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap"></div>
                                    <div className="d-flex mt-3">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {userLoanDetail?.manStatus ==
                                            "Open" ? (
                                              formatLargeNumber(
                                                userLoanDetail.mandatory,
                                                2
                                              )
                                            ) : (
                                              <span style={{ color: "red" }}>
                                                Close
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Mandatory Savings
                                        </div>
                                      </div>

                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              userLoanDetail?.volantory || 0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Voluntary Savings
                                        </div>
                                      </div>
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              fullinfosharewithdraw?.totalAmount ||
                                                0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Share Amount (ETB)
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                         { (fullinfosharewithdraw?.quantity)}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Share Quantity
                                        </div>
                                      </div>

                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              fullinfosharewithdraw?.currentSharePrice ||
                                                0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Current Share Price (ETB)
                                        </div>
                                      </div>
                                      {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${fullinfosharewithdraw?.buyingAmount ||
                                              0
                                              }`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Investment Amount
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>

                                  {/* <!--begin::Progress--> */}

                                  {/* <!--end::Progress--> */}
                                </div>
                              </div>
                              {/* <!--end::Info--> */}
                            </div>
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                onClick={() => setactiveTab("addshare")}
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#addshare"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                ADD Share
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                onClick={() => setactiveTab("sharewithdrawal")}
                                href="#sharewithdrawal"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Share WITHDRAWAL
                              </a>
                            </li>

                            {/* <li className="nav-item my-1" role="presentation">
                              <a
                                onClick={() => setactiveTab("ShareLogs")}
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#ShareLogs"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Share Logs
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    {/* Add Share  */}

                    <div
                      className="tab-pane fade active show"
                      id="addshare"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    // onSubmit={(e) => AddShareData(e)}
                                    onSubmit={(e) =>
                                      DeleteVeriflPassyAfterSubmit(e)
                                    }
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                              Quantity
                                            </label>
                                            <div className="col-lg-7 fv-row">
                                              <input
                                                required
                                                type="text"
                                                name="quantity"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter share quantity"
                                                step="any"
                                                min={1}
                                                value={typeof Quantity === 'number' ? Quantity.toFixed(2) : Quantity}
                                                onChange={handleCalculate}
                                                
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                              Share Price (ETB)
                                            </label>
                                            <div className="col-lg-7 fv-row">
                                              <input
                                                required
                                                readOnly
                                                type="number"
                                                name="sharePrice"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Number of share Price "
                                                min={1}
                                                value={latestSharePrice}
                                                onChange={(e) =>
                                                  setSharePrice(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                              Invest Amount (ETB)
                                            </label>
                                            <div className="col-lg-7 fv-row">
                                              <input
                                                type="number"
                                                name="investAmount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter Invest Amount"
                                                step="any"
                                                min={1}
                                                value={investAmount}
                                                onChange={handleCalculate}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                              Pay Method
                                            </label>
                                            <div className="col-lg-7 fv-row">
                                 

                                              <select
                                                name="payType"
                                                required
                                                className="form-select  flex-grow-1"
                                           
                                                data-placeholder="Please Select"
                                                onChange={(e) =>
                                                  setDeposite(e.target.value)
                                                }
                                              >
                                                <option value={"volantary"}>
                                                  Choose the Pay Method
                                                </option>
                                                <option value={"volantary"}>
                                                  Voluntary
                                                </option>

                                                <option value={"cash"}>
                                                  Cash
                                                </option>
                                                <option value={"BackDeposit"}>
                                                  Bank Deposit
                                                </option>
                                         
                                              </select>
                                            </div>
                                          </div>
                                        </div> */}

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                              Pay Method
                                            </label>
                                            <div className="col-lg-7 fv-row">
                                              <select
                                                name="payType"
                                                required
                                                className="form-select  flex-grow-1"
                                                // data-control="select2"
                                                data-placeholder="Please Select"
                                                onChange={(e) =>
                                                  setDeposite(e.target.value)
                                                }
                                              >
                                                <option value="">
                                                  Choose the Pay Method
                                                </option>

                                                <option value={"volantary"}>
                                                  Voluntary
                                                </option>

                                                <option value={"cash"}>
                                                  Cash
                                                </option>
                                                <option value={"BackDeposit"}>
                                                  Bank Deposit
                                                </option>

                                                {/* Add other loan types as needed */}
                                              </select>
                                            </div>
                                          </div>
                                        </div>

                                        {depositeBank === "BackDeposit" ? (
                                          <div className="row mt-5">
                                            <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                              Deposit Slip
                                            </label>
                                            <div className="col-lg-7 fv-row">
                                              <input
                                                type="file"
                                                name="depositSlip"
                                                accept=".jpg, .jpeg, .png, .pdf"
                                                className="form-control"
                                                required
                                                onChange={(e) =>
                                                  handleFileChange(
                                                    e.target.files[0]
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          // onClick={() =>
                                          //   DeleteVeriflPassyAfterSubmit()
                                          // }
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="cardlabel fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    {/* <div className="d-flex">
                                      <select
                                        name="marriageStatus"
                                        className="form-select border-0 flex-grow-1"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                          setfilterdata(e.target.value)
                                        }
                                      >
                                        <option value={"all"}>All</option>
                                        <option value={"voluntary"}>
                                          Voluntary
                                        </option>
                                        <option value={"mandatory"}>
                                          Mandatory
                                        </option>
                                      </select>
                                    </div> */}
                                  </div>
                                </div>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Investing <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">Quantity</th>
                                      <th className="min-w-150px">
                                        Share Price (ETB)
                                      </th>
                                      <th className="min-w-100px">
                                        Invest Amount (ETB)
                                      </th>
                                      <th className="min-w-100px">Status</th>
                                      <th className="min-w-100px">Cancel</th>
                                      <th className="min-w-100px">Invoice</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {shareBuySell?.map((item) => (
                                      <tr key={item?.id}>
                                        <td>
                                          {moment(item?.investingDate).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item?.quantity?.toFixed(2)}</td>

                                        <td>{item?.sharePrice}</td>
                                        <td>{item?.investAmount}</td>
                                        <td>{item?.withdStatus}</td>
                                        <td>
                                          <button
                                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="Cancel"
                                            type="button"
                                            onClick={() => {
                                              setShowModel(true);
                                              settransId(item?._id);
                                            }}
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <img
                                                src="/assets_new/images/cross.png"
                                                width="20px"
                                              />
                                            </span>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              handleDownloadInvoice(
                                                item.invoice
                                              )
                                            }
                                          >
                                            Download Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClickpage}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  Share withdraw */}

                    <div
                      className="tab-pane fade"
                      id="sharewithdrawal"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) =>
                                      DeleteVeriflyAfterSubmit(e)
                                    }
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-1">
                                          <div className="row mb-2">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Share Quantity
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                readOnly
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter Share Quantity"
                                                step="any"
                                                value={
                                                  (fullinfosharewithdraw?.quantity)?.toFixed(2)
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* <div className="row mb-2">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Share Buy Price
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                readOnly
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter Share Quantity"
                                                step="any"
                                                value={
                                                  fullinfosharewithdraw?.buyingPrice
                                                }
                                              />
                                            </div>
                                          </div> */}
                                          <div className="row mb-2">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Current Share Price (ETB)
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                readOnly
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter Share Quantity"
                                                step="any"
                                                value={
                                                  fullinfosharewithdraw?.currentSharePrice ||
                                                  0
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* <div className="row mb-2">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Investment Amount
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                readOnly
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter Share Quantity"
                                                step="any"
                                                value={
                                                  fullinfosharewithdraw?.buyingAmount
                                                }
                                              />
                                            </div>
                                          </div> */}
                                          <div className="row mb-1">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Current Amount (ETB)
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                readOnly
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter Share Quantity"
                                                step="any"
                                                value={
                                                  fullinfosharewithdraw?.totalAmount
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          // onClick={() =>
                                          //   DeleteVeriflyAfterSubmit()
                                          // }
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="cardlabel fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    {/* <div className="d-flex">
                                      <select
                                        name="marriageStatus"
                                        className="form-select border-0 flex-grow-1"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                          setfilterdata(e.target.value)
                                        }
                                      >
                                        <option value={"all"}>All</option>
                                        <option value={"voluntary"}>
                                          Voluntary
                                        </option>
                                        <option value={"mandatory"}>
                                          Mandatory
                                        </option>
                                      </select>
                                    </div> */}
                                  </div>
                                </div>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  id="table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Investing <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">Quantity</th>
                                      <th className="min-w-150px">
                                        Share Price (ETB)
                                      </th>
                                      <th className="min-w-100px">
                                        Invest Amount (ETB)
                                      </th>
                                      <th className="min-w-100px">Status</th>
                                      <th className="min-w-100px">Cancel</th>
                                      <th className="min-w-100px">Invoice</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {shareBuySell?.map((item) => (
                                      <tr key={item?.id}>
                                        <td>
                                          {moment(item?.investingDate).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item?.quantity?.toFixed(2)}</td>

                                        <td>{item?.sharePrice}</td>
                                        <td>{item?.investAmount}</td>
                                        <td>{item?.withdStatus}</td>
                                        <td>
                                          <button
                                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="Cancel"
                                            type="button"
                                            onClick={() => {
                                              setShowModel(true);
                                              settransId(item?._id);
                                            }}
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <img
                                                src="/assets_new/images/cross.png"
                                                width="20px"
                                              />
                                            </span>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              handleShareWithdrawalDownloadInvoice(
                                                item.invoice
                                              )
                                            }
                                          >
                                            Download Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClickpage}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start ">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Latest Share Price
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <form
                                onSubmit={(e) => SharePriceFilterGraph(e)}
                                className="card-title d-flex justify-content-between align-items-end"
                              >
                                <div className=" d-flex input-container align-items-center mb-3  ps-0 mb-lg-0">
                                  <label className="small_label pb-2">
                                    Start Date
                                  </label>
                                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                    <i className="fad fa-calendar fs-2"></i>
                                  </span>
                                  <input
                                    type="date"
                                    className="form-control unstyled form-control-flush flex-grow-1"
                                    name="fromDate"
                                    onChange={(e) =>
                                      setstartDate(e.target.value)
                                    }
                                  />
                                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                </div>

                                <div className=" d-flex align-items-center mb-3 input-container ps-0 mb-lg-0">
                                  <label className="small_label pb-2">
                                    End Date
                                  </label>
                                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                    <i className="fad fa-calendar fs-2"></i>
                                  </span>
                                  <input
                                    type="date"
                                    className="form-control unstyled form-control-flush flex-grow-1"
                                    name="toDate"
                                    min={startDate}
                                  />
                                </div>
                                <div className="min-w-150px text-end d-flex">
                                  <button
                                    type="submit"
                                    className="btn btn-light btn-sub "
                                    id="kt_advanced_search_button_1"
                                  >
                                    Search
                                  </button>

                                  <button
                                    type="reset"
                                    onClick={SharePriceGraph}
                                    className="btn btn-secondary mx-sm-5"
                                    id="kt_advanced_search_button_1"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </form>
                            </div>

                            <div className="card-body py-3">
                              <div className="bg-white overflow-auto card">
                                

                                <div id="chart">
                                  <ReactApexChart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="candlestick"
                                    height={350}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    {/* Share Lockes */}

                    {/* <div
                      className="tab-pane fade"
                      id="ShareLogs"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-1">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <div className="text-end">
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                                  table="VoluntaryToMandatory-table-to-xls"
                                  filename="tablexls"
                                  sheet="tablexls"
                                  buttonText={
                                    <>
                                      <i className="fad fa-download fs-1"></i>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table
                                  table="VoluntaryToMandatory-table-to-xls"
                                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                >
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>
                                      <th className="min-w-100px">
                                        Interest
                                        <br /> Rate{" "}
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Status <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody></tbody>
                                </table>
                              </div>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* details modal  */}

          <Modal
            className="modal-center"
            show={Delethandler}
            onHide={() => setDelethandler(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyDelete}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Verify
                </Button>
              </Modal.Footer>
            </form>
          </Modal>

          <Modal
            className="modal-center"
            show={verifySharepass}
            onHide={() => setVerifyShare(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyAddSharePassDelete}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Verify
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>

      <ModalPasswordVerify
        ShowModel={ShowModel}
        setShowModel={setShowModel}
        handleSubmit={handleCancelTransaction}
      />
    </>
  );
}
